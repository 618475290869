import { ref, toRaw } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'
import { useRouter } from 'vue-router'

export default function useDespachos() {

  const despacho = ref([])
  const chofer = ref([])
  const fsc = ref([])
  const despachos = ref([])
  const choferes = ref([])
  const fscs = ref([])
  const despachosDelete = ref([])
  const es_venta = ref()
  const porciva = ref()
  const messages = ref([])
  const router = useRouter()

  const client = useClient()

  const clientes = ref([])
  const despachadores = ref([])
  const tipoDocumentos = ref([])
  const destinos = ref([])
  const origenes = ref([])
  const transportistas = ref([])
  const productos = ref([])
  const precio = ref(0)
  const lineas = ref([])
  const first = ref(0)
  const lazyParams = ref({})
  const totalRecords = ref(0)

  const columns = ref([
    { field: 'producto_obj', header: 'Cod.', hidden: true },
    { field: 'producto', header: 'Producto', hidden: false },
    { field: 'espesor', header: 'Espesor', hidden: false },
    { field: 'ancho', header: 'Ancho', hidden: false },
    { field: 'largo', header: 'Largo', hidden: false },
    { field: 'cant_despacho', header: 'Cant.Desp', hidden: false },
    { field: 'mc_despacho', header: 'M3.Desp', hidden: false },
    { field: 'pulg_despacho', header: 'Pulg.Desp', hidden: false },
    { field: 'cant_recepcion', header: 'Cant.Recep', hidden: false },
    { field: 'mc_recepcion', header: 'M3.Recep', hidden: false },
    { field: 'pulg_recepcion', header: 'Pulg.Recep', hidden: false },
    { field: 'precio', header: 'Valor', hidden: false },
    { field: 'tipo_cambio', header: 'T.Cambio', hidden: true },
    { field: 'total', header: 'Total', hidden: true },
    { field: 'total_mask', header: 'Total', hidden: false }
  ])

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedDespachos = ref()
  const deleteDespachosDialog = ref(false)
  const deleteDespachoDialog = ref(false)
  const esVentaDespachoDialog = ref(false)
  const despachoDialog = ref(false)
  const submitted = ref(false)

  const filteredClientes = ref()
  const filteredDespachadores = ref()
  const filteredFscs = ref()
  const filteredChoferes = ref()
  const filteredTipoDocumentos = ref()
  const filteredDestinos = ref()
  const filteredOrigenes = ref()
  const filteredProductos = ref()
  const filteredTransportistas = ref()

  const getFactura = async (d) => {
    loading.value = true
    let id = d.id
    client.get(`/api/despachos/${id}/factura`)
      .then(response => {
        if(response.data.data.guia_id === id){
          router.push({ name: "facturas.index", params: { guia: id} })  
        }else{
          getPDFSII(d)
        }
        loading.value = false
    })
  }

  const storeDespacho = async (form) => {
    saving.value = true
    //Esta creando el registro
    client.post(`/api/despachos`, { 'data': { ...form } })
    .then((response) => {
      if(response.status === 200){
        despachoDialog.value = client.dialog.value
        despacho.value = client.entity.value
        saving.value = client.saving.value
        loadLazyData()
      }else{
        //Mostrar razon del error y habilita de nuevo el boton guardar
        despachoDialog.value = true
        despacho.value = client.entity.value
        saving.value = false          
      }
    })
  }

  const deleteDespacho = async () => {
    client.remove(`/api/despachos/${despacho.value.id}`)
      .then(response => {
        despachos.value = despachos.value.filter(val => val.id !== despacho.value.id)
        deleteDespachoDialog.value = false
        despacho.value = {}
      })
  }

  const esVentaDespacho = async(esventa) => {
    client.patch(`/api/despachos/${despacho.value.id}/${esventa}`, { 'dialog': 'esVentaDespachoDialog'})
    .then(() => {
      esVentaDespachoDialog.value = false
      despachoDialog.value = false     
      loadLazyData()   
      saving.value = false
    })   
  }

  const deleteSelectedDespachos = async () => {
    despachosDelete.value = selectedDespachos.value.map(({ id }) => id)
    client.remove(`/api/despachos/destroy/mass`, { data: despachosDelete.value })
      .then(response => {
        despachos.value = despachos.value.filter(
          (val) => !selectedDespachos.value.includes(val)
        )
        deleteDespachosDialog.value = false
        selectedDespachos.value = null
        despachosDelete.value = null
      })
  }

  const loadLazyData = (event) => {
    lazyParams.value = { ...lazyParams.value, first: event?.first || first.value }
    
    client.get(`/api/despachos/all/${lazyParams.value.first}/${lazyParams.value.rows}`,{ data: lazyParams.value }).then(response => {
      despachos.value = response.data.data.despachos
      despachadores.value = response.data.data.despachadores
      fscs.value = response.data.data.fscs
      choferes.value = response.data.data.choferes
      tipoDocumentos.value = response.data.data.tipoDocumentos
      destinos.value = response.data.data.origenes
      origenes.value = response.data.data.origenes
      productos.value = response.data.data.productos
      clientes.value = response.data.data.clientes
      transportistas.value = response.data.data.transportistas
      
      porciva.value = response.data.data.porciva
      totalRecords.value = response.data.data.totalRecords
      loading.value = false
    })
    .catch(function (error) { 
      loading.value = false
    })
  }

  const onPage = (event) => {
    lazyParams.value = event
    loadLazyData(event)
  }

  const onSort = (event) => {
    lazyParams.value = event
    loadLazyData(event)
  }

  const onFilter = (event) => {
    lazyParams.value.filters = filters.value 
    loadLazyData(event)
  }

  const getPreciosContrato = async (cid,pid,did) => {
    loading.value = true
    if(did !== undefined && pid !== undefined && did !== undefined)
      client.get(`/api/contratos/${cid}/${pid}/${did}/precios`).then(response => {
        precio.value = response.data.data.precio
        loading.value = false
      })
  }

  const getPDFSII = async (d) => {
    loading.value = true
    let tdoc = d.tipoDocumento ? toRaw(tipoDocumentos.value.filter(function (itm) { return [parseInt(d.tipoDocumento.id)].indexOf(itm.id) > -1 }))[0] : null

    let rut_dv = '76492850-4'

    if(d.folio !== undefined)
      axios.get(`/api/despachos/${tdoc.codigo_sii}/${d.folio}/E/${rut_dv}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'tipoDocumento': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
    'fecha': { value: null, matchMode: FilterMatchMode.EQUALS },
    'nro_oc': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },    
    'cliente': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'origen': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'destino': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'transportista': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'neto': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'iva': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'total': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'es_venta': { value: null, matchMode: FilterMatchMode.EQUALS },  
  })

  const initFilters = () => {
    filters.value = {
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

      'tipoDocumento': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
      'fecha': { value: null, matchMode: FilterMatchMode.EQUALS },
      'nro_oc': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },    
      'cliente': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'origen': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'destino': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'transportista': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'neto': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'iva': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'total': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'es_venta': { value: null, matchMode: FilterMatchMode.EQUALS },  
    }
    lazyParams.value.filters = filters.value
    loadLazyData()
  }

  return {
    despacho,
    fsc,
    chofer,
    es_venta,
    porciva,
    despachos,
    fscs,
    choferes,
    clientes,
    despachadores,
    tipoDocumentos,
    destinos,
    origenes,
    dt,
    loading,
    saving,
    filters,
    initFilters,
    selectedDespachos,
    deleteDespachosDialog,
    deleteDespachoDialog,
    esVentaDespachoDialog,
    despachoDialog,
    submitted,
    filteredClientes,
    filteredFscs,
    filteredDespachadores,
    filteredChoferes,
    filteredTipoDocumentos,
    filteredDestinos,
    filteredOrigenes,
    filteredProductos,
    filteredTransportistas,
    productos,
    precio,
    transportistas,
    lineas,
    columns,
    messages,
    deleteSelectedDespachos,
    storeDespacho,
    deleteDespacho,
    esVentaDespacho,
    getFactura,
    totalRecords,
    first,
    lazyParams,
    loadLazyData,
    onPage,
    onSort,
    onFilter,
    getPreciosContrato,
    getPDFSII
  }
}
