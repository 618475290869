<template>
  <Toast/>
  <div class="card-header row">
    <h4 class="col-md-10">Facturación</h4>
    <div class="col-md-2" style="text-align:right;"></div>
    <div class="line_title mt-1 ms-3 col-md-1"></div>
  </div>
  <div class="card-body">
    <div v-if="factura.id === undefined || fromDespachos === false">
      <!-- DT Facturas  -->
      <DataTable ref="dt" :value="facturas" lazy paginator :first="first" :loading="loading" 
        removableSort sortMode="multiple" @sort="onSort($event)" 
        :rows="10" :totalRecords="totalRecords" @page="onPage($event)" @filter="onFilter($event)" 
        v-model:filters="filters" :globalFilterFields="['tipoDocumento','folio', 'fecha', 'nro_oc', 'rut_dv','cliente', 'origen','destino','guias_despacho','transportista','neto', 'iva', 'total']" filterDisplay="row" tableStyle="min-width: 75rem" breakpoint="990px" style="zoom: 0.80;" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"> 
        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." @keydown.enter="onFilter($event)"/>
                </span>
              </div>
            </template>
            <template #empty> Sin registros. </template>
            <template #loading> Cargando registros. </template>
            <template #end>
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" outlined @click="initFilters()" class="p-button-secondary"/>&nbsp;
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
            </template>
          </Toolbar>
        </template>

        <Column field="tipoDocumento" header="T.Doc" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.tipoDocumento.codigo" :severity="slotProps.data.tipoDocumento.codigo === 'FE' ? 'info' : 'warning'" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>
        </Column>        

        <Column field="folio" header="Folio" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="fecha" header="Fecha" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.fecha }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>
        </Column>

        <Column field="nro_oc" header="Nro.OC" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.nro_oc }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="rut_dv" header="RUT" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.cliente.rut_dv }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="cliente" header="Cliente" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.cliente.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="origen" header="Origen" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.origen ? slotProps.data.origen.descripcion.slice(0, 23) : null }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="destino" header="Destino" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.destino ? slotProps.data.destino.descripcion.slice(0, 23) : '' }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="guias_despacho" header="Guias" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.guias_despacho ? slotProps.data.guias_despacho.replace('Venta según guía(s): ', '').replace('Venta Segun Guía Nº /', '').slice(0, 10).replace(', ',', ...') : ''}}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="neto" header="Neto" sortable>
          <template #body="slotProps">
            <span>
              {{ parseInt(slotProps.data.neto).toLocaleString('es-CL') }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>            

        <Column field="iva" header="IVA" sortable>
          <template #body="slotProps">
            <span>
              {{ parseInt(slotProps.data.iva).toLocaleString('es-CL') }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>            

        <Column field="total" header="V.Total" sortable>
          <template #body="slotProps">
            <span>
              {{ parseInt(slotProps.data.total).toLocaleString('es-CL') }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>            

        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info"
              @click="getPDFSII(slotProps.data)" title="Ver" />&nbsp;
            <Button icon="pi pi-eye" class="p-button-rounded p-button-warning"
                    @click="editFactura(slotProps.data)"/>&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                    @click="confirmDeleteFactura(slotProps.data)"/>
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-else style="width: 100%; height: 100px;left: 5%; right: 15%;">
      <InlineMessage v-for="msg of messages" :key="msg.id" severity="info" class="mb-2" style="width: 100%;">
        {{ msg.content }}
      </InlineMessage>
      <ProgressBar v-if="messages.length > 0 &&  fromDespachos === true" mode="indeterminate"
        style="height: 6px"></ProgressBar>
    </div>
    <!-- Formulario Facturas en modal -->
    <Dialog v-model:visible="facturaDialog" header="Facturación de Guías" :modal="true" class="p-fluid"
            :maximizable="true" position="topleft" @update:visible="onHideDialogForm">
      <form class="p-fluid">
        <TabView>
          <TabPanel header="Datos de la Factura">
            <div class="row">
              <div class="col-md-5">
                <div class="row">
                  <div class="field"
                      :class="factura.id === undefined && fromDespachos === false ? 'col-md-7' : 'col-md-12'">
                    <label for="cliente">Cliente</label>
                    <AutoComplete id="cliente" v-model="v$.cliente.$model"
                      :class="{ 'p-invalid': v$.cliente.$invalid && submitted }"
                      :suggestions="filteredClientes"
                      @complete="searchCliente($event)" field="_razon_social" :dropdown="true"
                      forceSelection
                      aria-describedby="cliente-error" autofocus autocomplete="off"/>
                    <span v-if="v$.cliente.$error && submitted">
                      <span id="cliente-error" v-for="(error, index) of v$.cliente.$errors" :key="index">
                        <small class="p-error">{{ error.$message }}</small>
                      </span>
                    </span>
                    <small v-else-if="((v$.cliente.$invalid && submitted) || v$.cliente.$pending.$response)"
                          class="p-error">
                      {{ v$.cliente.required.$message.replace('Value', 'Cliente') }}</small>
                  </div>
                  <div class="col-md-2 field" v-if="factura.id === undefined && fromDespachos === false">
                    <label for="fecha">F.Desde</label>
                    <Calendar id="fdesde" v-model="form.fdesde" autocomplete="off"
                              :showIcon="false" dateFormat="dd/mm/y" autofocus/>
                  </div>
                  <div class="col-md-2 field" v-if="factura.id === undefined && fromDespachos === false">
                    <label for="fhasta">F.Hasta</label>
                    <Calendar id="fhasta" v-model="form.fhasta" autocomplete="off" :showIcon="false"
                              dateFormat="dd/mm/y" autofocus/>
                  </div>
                  <div class="col-md-1" v-if="factura.id === undefined && fromDespachos === false">
                    <label for="search">Buscar</label>
                    <Button icon="pi pi-search" class="p-button-primary" @click="buscarGuia" outlined
                            :disabled="(form.fdesde === null || form.fhasta === null || form.cliente === null)"/>
                  </div>
                </div>
                <div class="row mt-4" v-if="guias.length > 0">
                  <DataTable ref="dtg" :value="guias" :paginator="true" :loading="loading" 
                    removableSort sortMode="multiple" class="sm" showGridlines stripedRows v-model:selection="selectedGuias" :rows="10" v-model:filters="gfilters" :globalFilterFields="['gfecha','gfolio','gneto','giva','gtotal','gnro_oc']" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" filterDisplay="row" responsiveLayout="scroll" breakpoint="990px" style="zoom: 0.80;" @rowSelect="onGuiaSelect" @row-select-all="onSelectAllChange" @row-unselect="onGuiaSelect" @row-unselect-all="onSelectAllChange">
                    <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>                   
                    <Column field="fecha" header="Fecha2" sortable>
                      <template #body="slotProps">
                        <span>
                          {{ formatFecha(slotProps.data.fecha) }}
                        </span>
                      </template>
                      <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                      </template>                      
                    </Column>
                    <Column field="folio" header="Guía" sortable>
                      <template #body="slotProps">
                        <span>
                          {{ slotProps.data.folio }}
                        </span>
                      </template>
                      <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                      </template>                      
                    </Column>
                    <Column field="neto" header="Neto" sortable>
                      <template #body="slotProps">
                        <span>
                          {{ formatCurrency(slotProps.data.neto) }}
                        </span>
                      </template>
                      <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                      </template>                      
                    </Column>
                    <Column field="iva" header="IVA" sortable>
                      <template #body="slotProps">
                        <span>
                          {{ formatCurrency(slotProps.data.iva) }}
                        </span>
                      </template>
                      <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                      </template>                      
                    </Column>
                    <Column field="total" header="Total" sortable>
                      <template #body="slotProps">
                          <span>
                          {{ formatCurrency(slotProps.data.total) }}
                          </span>
                      </template>
                      <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                      </template>                      
                    </Column>
                    <Column field="nro_oc" header="Nro.OC" sortable>
                      <template #body="slotProps">
                          <span>
                          {{ formatCurrency(slotProps.data.nro_oc) }}
                          </span>
                      </template>
                      <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>

              <div class="col-md-7">
                <div class="row">
                  <div class="col-md-4 field">
                    <label for="tipoVenta">Tipo Venta</label>
                    <AutoComplete id="tipoVenta" v-model="v$.tipoVenta.$model"
                                  :class="{ 'p-invalid': v$.tipoVenta.$invalid && submitted }"
                                  :suggestions="filteredTipoVentas"
                                  @complete="searchTipoVenta($event)" field="nombre" :dropdown="true" forceSelection
                                  aria-describedby="tipoVenta-error" autofocus autocomplete="off"/>
                    <span v-if="v$.tipoVenta.$error && submitted">
                      <span id="tipoVenta-error" v-for="(error, index) of v$.tipoVenta.$errors" :key="index">
                        <small class="p-error">{{ error.$message }}</small>
                      </span>
                    </span>
                    <small v-else-if="((v$.tipoVenta.$invalid && submitted) || v$.tipoVenta.$pending.$response)"
                          class="p-error">
                      {{ v$.tipoVenta.required.$message.replace('Value', 'Tipo Venta') }}</small>
                  </div>
                  <div class="col-md-4 field">
                    <label for="tipoDocumento">Tipo Documento</label>
                    <AutoComplete id="tipoDocumento" v-model="v$.tipoDocumento.$model"
                      :class="{ 'p-invalid': v$.tipoDocumento.$invalid && submitted }"
                      :suggestions="filteredTipoDocumentos"
                      @complete="searchTipoDocumento($event)" field="_nombre" :dropdown="true"
                      forceSelection @change="setTotal($event, index)"
                      aria-describedby="tipoDocumento-error" autofocus autocomplete="off"/>
                    <span v-if="v$.tipoDocumento.$error && submitted">
                      <span id="tipoDocumento-error" v-for="(error, index) of v$.tipoDocumento.$errors" :key="index">
                        <small class="p-error">{{ error.$message }}</small>
                      </span>
                    </span>
                    <small v-else-if="((v$.tipoDocumento.$invalid && submitted) || v$.tipoDocumento.$pending.$response)"
                          class="p-error">
                      {{ v$.tipoDocumento.required.$message.replace('Value', 'Tipo Documento') }}</small>
                  </div>
                  <div class="col-md-2 field">
                    <label for="folio">N°Folio</label>
                    <InputNumber id="folio" v-model.trim="v$.folio.$model" :class="{ 'p-invalid': v$.folio.$invalid && submitted }" aria-describedby="razon_social-error" autofocus autocomplete="off"/> <!-- :readonly="form.folio !== null" -->
                    <span v-if="v$.folio.$error && submitted">
                      <span id="folio-error" v-for="(error, index) of v$.folio.$errors" :key="index">
                        <small class="p-error">{{ error.$message }}</small>
                      </span>
                    </span>
                    <small v-else-if="(v$.folio.$invalid && submitted) || v$.folio.$pending.$response" class="p-error">
                      {{ v$.folio.required.$message.replace('Value', 'Folio') }}</small>                    
                  </div>
                  <div class="col-md-2 field">
                    <label for="fecha">Fecha</label>
                    <Calendar id="fecha" v-model="v$.fecha.$model"
                              :class="{ 'p-invalid': v$.fecha.$invalid && submitted }" aria-describedby="fecha-error"
                              autocomplete="off" :showIcon="false" dateFormat="dd/mm/y"/>
                    <span v-if="v$.fecha.$error && submitted">
                      <span id="fecha-error" v-for="(error, index) of v$.fecha.$errors" :key="index">
                        <small class="p-error">{{ error.$message }}</small>
                      </span>
                    </span>
                    <small v-else-if="(v$.fecha.$invalid && submitted) || v$.fecha.$pending.$response"
                          class="p-error">
                      {{ v$.fecha.required.$message.replace('Value', 'Fecha') }}</small>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 field">
                    <label for="origen" class="mt-1">Origen</label>
                    <AutoComplete id="origen" v-model="form.origen"
                                  :suggestions="filteredOrigenes"
                                  @complete="searchOrigen($event)" field="_descripcion" :dropdown="true" forceSelection
                                  autofocus autocomplete="off"/>
                  </div>
                  <div class="col-md-4 field">
                    <label for="destino" class="mt-1">Destino</label>
                    <AutoComplete id="destino" v-model="form.destino"
                                  :suggestions="filteredDestinos"
                                  @complete="searchDestino($event)" field="_descripcion" :dropdown="true" forceSelection
                                  autofocus autocomplete="off"/>
                  </div>
                  <div class="col-md-2 field">
                    <label for="patente_camion" class="mt-1">Patente Camión</label>
                    <InputText id="patente_camion" v-model="form.patente_camion"
                              autocomplete="off" minlength="6" maxlength="6"
                              size="6" @update:modelValue="upperCase($event,'patente_camion')"/>
                  </div>
                  <div class="col-md-2 field">
                    <label for="patente_carro" class="mt-1">Patente Carro</label>
                    <InputText id="patente_carro" v-model="form.patente_carro" autocomplete="off" minlength="6" maxlength="6" size="6" @update:modelValue="upperCase($event,'patente_carro')"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 field">
                    <label for="despachador">Despachador</label>
                    <AutoComplete id="despachador" v-model="form.despachador"
                                  :suggestions="filteredDespachadores"
                                  @complete="searchDespachador($event)" field="_nombre" :dropdown="true" forceSelection
                                  autofocus autocomplete="off"/>
                  </div>
                  <div class="col-md-4 field">
                    <label for="transportista" class="mt-1">Transportista</label>
                    <AutoComplete id="transportista" v-model="form.transportista"
                                  :suggestions="filteredTransportistas"
                                  @complete="searchTransportista($event)" field="_razon_social" :dropdown="true"
                                  forceSelection autofocus autocomplete="off"/>
                  </div>
                  <div class="col-md-4 field">
                    <label for="chofer" class="mt-1">Chofer</label>
                    <AutoComplete id="chofer" v-model="form.chofer"
                                  :suggestions="filteredChoferes"
                                  @complete="searchChofer($event)" field="_nombre" :dropdown="true" forceSelection
                                  autofocus autocomplete="off"/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 field">
                    <label for="nro_oc" class="mt-1">Nro.OC</label>
                    <InputNumber id="nro_oc" v-model.trim="form.nro_oc"/>
                    <small v-if="!detalleTipo && form.nro_oc === null" class="p-error">Requerido si detalles es x producto</small>
                  </div>
                  <div class="col-md-4 field">
                    <label for="fecha_oc" class="mt-1">Fecha OC</label>
                    <Calendar id="fecha_oc" v-model="form.fecha_oc" autocomplete="off"  :showIcon="false" dateFormat="dd/mm/y"/>
                    <small v-if="!detalleTipo && (form.fecha_oc === null || form.fecha_oc === '')" class="p-error">Requerido si detalles es x producto</small>
                  </div>
                  <div class="col-md-4 field">
                    <label for="flete" class="mt-1">V.Flete</label>
                    <InputNumber id="flete" v-model="form.flete" autofocus autocomplete="off"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 field">
                    <label for="tipoPago" class="mt-1">Tipo Pago</label>
                    <AutoComplete id="tipoPago" v-model="v$.tipoPago.$model"
                      :class="{ 'p-invalid': v$.tipoPago.$invalid && submitted }"
                      :suggestions="filteredTipoPagos"
                      @complete="searchTPago($event)" field="_nombre" :dropdown="true" forceSelection
                      aria-describedby="tipoPago-error" autofocus autocomplete="off"/>
                    <span v-if="v$.tipoPago.$error && submitted">
                      <span id="tipoPago-error" v-for="(error, index) of v$.tipoPago.$errors" :key="index">
                        <small class="p-error">{{ error.$message }}</small>
                      </span>
                    </span>
                    <small v-else-if="((v$.tipoPago.$invalid && submitted) || v$.tipoPago.$pending.$response)"
                          class="p-error">
                      {{ v$.tipoPago.required.$message.replace('Value', 'Tipo Pago') }}</small>
                  </div>
                  <div class="col-md-4 field">
                    <label for="fsc" class="mt-1">FSC</label>
                    <AutoComplete id="fsc" v-model="form.fsc"
                      :suggestions="filteredFscs"
                      @complete="searchFsc($event)" field="nombre" :dropdown="true" forceSelection
                      autofocus autocomplete="off" @change="setFscCodigo($event, index)"/>
                  </div>
                  <div class="col-md-4 field">
                    <label for="fsc_codigo" class="mt-1">Código FSC</label>
                    <InputText
                      id="fsc_codigo"
                      v-model.trim="form.fsc_codigo"
                      readonly/>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Detalles de la Factura">
            <div class="row">
              <div class="col-md-1">
                <h5 class="mt-2" for="detalleTipo">TIPO DETALLE:</h5>
              </div>
              <div class="col-md-2">
                <ToggleButton v-model="detalleTipo" class="col-md-2" onLabel="Venta Según Guías" 
                  offLabel="Venta Según Producto" onIcon="pi pi-list"  offIcon="pi pi-tags" @change="setDetalleTipo($event)"/>
              </div>
              <div class="col-md-9">
                <span class="text-muted mt-2"></span>
              </div>
            </div>
            <Splitter layout="vertical" class="mt-2">
              <SplitterPanel v-if="detalleTipo" class="flex align-items-center justify-content-center" :minSize="1">
                <Textarea id="guias_despacho" class="m-2" v-model="v$.guias_despacho.$model" variant="filled" rows="3" cols="1" :class="{ 'p-invalid': v$.guias_despacho.$invalid && submitted }" aria-describedby="guias_despacho-error" autofocus autocomplete="off" readonly/>
                <span v-if="v$.guias_despacho.$error && submitted">
                  <span id="guias_despacho-error" v-for="(error, index) of v$.guias_despacho.$errors" :key="index">
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small v-else-if="(v$.guias_despacho.$invalid && submitted) || v$.guias_despacho.$pending.$response"
                      class="p-error">
                  {{ v$.guias_despacho.required.$message.replace('Value', 'Guías de Despacho') }}
                </small>
              </SplitterPanel>
              <SplitterPanel class="flex align-items-center justify-content-center">
                <div class="card p-fluid table_modal mt-2">
                  <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px"
                  tableClass="editable-cells-table" tableStyle="min-width: 50rem" editMode="cell"
                  @cell-edit-complete="onCellEditComplete">
                    <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
                            :hidden="col.hidden" :style="col.size">
                      <template #editor="{ data, field }">
                        <template v-if="field === 'observacion'">
                          <InputText else v-model="data[field]" autofocus/>
                        </template>
                        <template v-else-if="(field !== 'producto' && field !== 'observacion')">
                          <InputNumber else v-model="data[field]" autofocus :useGrouping="true" :maxFractionDigits="/cant_despacho|cant_recepcion|tipo_cambio/.test(field) ? 3 : 2" :readonly="/total|total_mask/.test(field)"/>
                        </template>
                        <template v-else>
                          <AutoComplete v-model="data[field]" optionLabel="_nombre" :optionValue="data[field]"
                            :suggestions="filteredProductos" @complete="searchProducto($event)" autofocus @focus="selAllText"/>
                        </template>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </SplitterPanel>
            </Splitter>
          </TabPanel>
        </TabView>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-5" v-if="factura.id === undefined">
            <div class="row mt-1 ms-2" v-if="selectedGuias.length > 0">
              <!-- <DataTable ref="dtg" :value="selectedGuias" scrollable scrollHeight="300px"
                        style="zoom: 0.95;" :loading="loading"
                        columnResizeMode="fit" responsiveLayout="scroll" class="sm" showGridlines stripedRows
                        v-model:selection="selectedGuias">
                <Column field="fecha" header="Fecha">
                  <template #body="slotProps">
                    <span>
                      {{ formatFecha(slotProps.data.fecha) }}
                    </span>
                  </template>
                </Column>
                <Column field="folio" header="Guía">
                  <template #body="slotProps">
                    <span>
                      {{ slotProps.data.folio }}
                    </span>
                  </template>
                </Column>
                <Column field="neto" header="Neto">
                  <template #body="slotProps">
                    <span>
                      {{ formatCurrency(slotProps.data.neto) }}
                    </span>
                  </template>
                </Column>
                <Column field="iva" header="IVA">
                  <template #body="slotProps">
                    <span>
                      {{ formatCurrency(slotProps.data.iva) }}
                    </span>
                  </template>
                </Column>
                <Column field="total" header="Total">
                  <template #body="slotProps">
                      <span>
                      {{ formatCurrency(slotProps.data.total) }}
                      </span>
                  </template>
                </Column>
                <Column field="nro_oc" header="Nro.OC">
                    <span>
                      {{ slotProps.data.nro_oc }}
                    </span>
                </Column>
              </DataTable> -->
            </div>
          </div>
          <div v-else class="col-md-5 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id"><i class="pi pi-user-plus" style="font-size: 1rem"></i>:
              {{ form.created_id.name }} el {{ form.created_at }}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>:
              {{ form.updated_id.name }} el {{ form.updated_at }}</small>
          </div>
          <div class="col-md-7">
            <InlineMessage v-for="msg of messages" :key="msg.id" severity="info" class="mb-2">{{
                msg.content
              }}
            </InlineMessage>
            <div class="row">
              <div class="col-md-5">
                <div class="col-md-12 field text-align:left">
                  <Textarea autoResize id="" v-model.trim="form.monto_letras" rows="3" placeholder="Monto en Letras"
                            readonly/>
                </div>
                <div class="col-md-12 field text-align:left mt-2">
                  <Textarea autoResize id="" v-model.trim="form.observacion" rows="3" placeholder="Observación"/>
                </div>
              </div>
              <div class="col-md-5">
                <div class="row mt-1">
                  <div class="col-md-4 field">
                    <label for="descuento" class="fw-bold mt-3">Descuento</label>
                  </div>
                  <div class="col-md-8 field">
                    <InputNumber id="descuento" v-model.trim="form.descuento"/>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-4 field">
                    <label for="neto" class="fw-bold mt-3">Neto</label>
                  </div>
                  <div class="col-md-8 field">
                    <InputNumber id="neto" v-model.trim="form.neto" readonly/>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-4 field">
                    <label for="iva" class="fw-bold mt-3">IVA ({{ form.poriva }}%)</label>
                    <small v-if="form.tipoDocumento ? (form.tipoDocumento.codigo_sii == 46 ?? false) : false"> *Retenido</small>
                  </div>
                  <div class="col-md-8 field">
                    <InputNumber id="iva" v-model.trim="form.iva" readonly/>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-4 field">
                    <label for="total" class="fw-bold mt-3">Total</label>
                  </div>
                  <div class="col-md-8 field">
                    <InputNumber id="total" v-model.trim="form.total" readonly/>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog"/>
                    <br/><br/>
                    <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveFactura" v-if="factura.id === undefined"/>
                    <br/><br/>
                  </div>
                  <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
                    <ProgressBar mode="indeterminate"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un factura -->
    <Dialog v-model:visible="deleteFacturaDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;"/>
        <span v-if="factura"> ¿Está seguro de borrar el factura?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteFacturaDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteFactura"/>
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente facturas -->
    <Dialog v-model:visible="deleteFacturasDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;"/>
        <span v-if="selectedFacturas"> ¿Está seguro de borrar los facturas seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteFacturasDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedFacturas"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="muchasGuias" modal header="Error Folios Referencia" :style="{ width: '510px !important' }" class="text-danger">
      <h4>Ha superado el límite de referencias para un DTE.</h4><hr>
      <p class="m-0">
        Para facturar, citando más de 40 guías incluyendo la orden de compra, debe indicar el <strong>Tipo de Detalle</strong> como: «Venta Según Producto»
      </p>
    </Dialog>
  </div>
</template>

<script>
import useFacturas from '../../composables/procesos/facturas'
import useClient from '../../composables/client/apiClient'
import {reactive, ref, onMounted, isProxy, toRaw} from 'vue'
import moment from 'moment'
import writtenNumber from 'written-number'

import useVuelidate from '@vuelidate/core'
import {required, helpers } from '@vuelidate/validators'

export default {
  setup() {
    writtenNumber.defaults.lang = 'es'

    const {
      porciva,
      factura,
      chofer,
      fsc,
      facturas,
      nextid,
      clientes,
      despachadores,
      choferes,
      fscs,
      destinos,
      origenes,
      transportistas,
      productos,
      columns,
      dt,
      dtg,
      loading,
      saving,
      filters,
      gfilters,
      initFilters,
      guias,
      guia,
      selectedFacturas,
      selectedTpago,
      tpagos,
      deleteFacturasDialog,
      deleteFacturaDialog,
      facturaDialog,
      submitted,
      filteredClientes,
      filteredDespachadores,
      filteredFscs,
      filteredChoferes,
      filteredDestinos,
      filteredOrigenes,
      filteredTransportistas,
      filteredTipoPagos,
      filteredProductos,
      selectedProductos,
      selectedGuias,
      lineas,
      codguias,
      messages,
      detalleTipo,
      storeFactura,
      deleteSelectedFacturas,
      getGuias,
      // getNextFolio,
      deleteFactura,
      tipoDocumentos,
      filteredTipoDocumentos,
      tipoVentas,
      selectedTipoVenta,
      filteredTipoVentas,
      router,
      fromDespachos,
      totalRecords,
      first,
      gfirst,
      lazyParams,
      lazyParamsGuia,
      loadLazyData,
      onPage,
      onSort,
      onFilter,      
      getPDFSII 
    } = useFacturas()

    const muchasGuias = ref(false)

    const client = useClient()

    const form = reactive({
      tipoDocumento: '',
      tipoVenta: '',
      cliente: null,
      origen: '',
      destino: '',
      transportista: '',
      chofer: '',
      despachador: '',
      fsc: '',
      fsc_codigo: '',
      folio: 0,
      fecha: moment().toDate(),
      patente_camion: '',
      patente_carro: '',
      flete: null,
      poriva: porciva.value,
      guias_despacho: '',
      descuento: 0,
      neto: null,
      iva: null,
      total: null,
      nro_oc: null,
      fecha_oc: '',
      observacion: '',
      monto_letras: null,
      detalles: [],
      tipoPago: null,
      guias_despacho: null,
      guias: [],
      //para busqueda de guias
      fdesde: null,
      fhasta: null,
      detalleTipo: true
    })

    const rules = {
      cliente: {required: helpers.withMessage('El campo es requerido!', required),},
      folio: {required: helpers.withMessage('El campo es requerido', required),},
      fecha: {required: helpers.withMessage('El campo es requerido', required),},
      neto: {required: helpers.withMessage('El campo es requerido', required),},
      iva: {required: helpers.withMessage('El campo es requerido', required),},
      total: {required: helpers.withMessage('El campo es requerido', required),},
      tipoPago: {required: helpers.withMessage('El campo es requerido', required),},
      guias_despacho: {required: helpers.withMessage('Debe Seleccionar al menos una Guía de Despacho', required),},
      tipoDocumento: {required: helpers.withMessage('El campo es requerido o no corresponde a la despacho', required),},
      tipoVenta: {required: helpers.withMessage('El campo es requerido o no corresponde a la despacho', required),},
      detalleTipo: {required: helpers.withMessage('El campo es requerido o no corresponde a la despacho', required),},
    }

    const editFactura = (fact) => {
      lineas.value = []
      factura.value = {...fact}
      facturaDialog.value = true
      form.value = {}
      submitted.value = false
      saving.value = false
      guias.value = []
      codguias.value = []
      selectedGuias.value = []
      messages.value = []

      let detalles = isProxy(factura.value.productos) ? toRaw(factura.value.productos) : []

      lineas.value = detalles.reduce((acc, ln, index) => {
        acc.push({
          'idx': index + 1,
          'producto_obj': {'id': ln.id, 'nombre': ln.nombre, '_nombre': ln.id +'-'+ln.nombre},
          'producto': ln.nombre,
          'observacion': ln.pivot.observacion,
          'espesor': ln.pivot.espesor,
          'ancho': ln.pivot.ancho,
          'largo': ln.pivot.largo,
          'cant_despacho': ln.pivot.cant_despacho,
          'mc_despacho': ln.pivot.mc_despacho,
          'pulg_despacho': ln.pivot.pulg_despacho,
          'cant_recepcion': ln.pivot.cant_recepcion,
          'mc_recepcion': ln.pivot.mc_recepcion,
          'pulg_recepcion': ln.pivot.pulg_recepcion,
          'precio': ln.pivot.precio,
          'tipo_cambio': ln.pivot.tipo_cambio,
          'total': ln.pivot.total,
          'total_mask': ln.pivot.total
        });
        return acc;
      }, [])

      form.detalles = lineas.value
      form.cliente = factura.value.cliente
      form.tipoDocumento = factura.value.tipoDocumento
      form.origen = factura.value.origen
      form.destino = factura.value.destino
      form.transportista = factura.value.transportista
      form.chofer = factura.value.chofer
      form.despachador = factura.value.despachador
      form.fsc = factura.value.fsc
      form.fsc_codigo = factura.value.fsc_codigo
      form.folio = factura.value.folio
      form.fecha = factura.value.fecha
      form.tipoVenta = tipoVentas.value[factura.value.tipoVenta]
      form.patente_camion = factura.value.patente_camion
      form.patente_carro = factura.value.patente_carro
      form.flete = factura.value.flete
      form.poriva = factura.value.poriva
      form.descuento = factura.value.neto
      form.neto = factura.value.neto
      form.iva = factura.value.iva
      form.total = factura.value.total
      form.nro_oc = factura.value.nro_oc
      form.fecha_oc = factura.value.fecha_oc
      form.observacion = factura.value.observacion
      form.monto_letras = form.monto_letras === null ?
        (Number(form.total) > 0 ? writtenNumber(form.total).toUpperCase() : '') :
        factura.value.monto_letras
      form.guias_despacho = factura.value.guias_despacho
      form.vigente = factura.value.vigente
      form.tipoPago = factura.value.tipoPago
      form.guias = []
      form.created_at = factura.value.created_at
      form.created_id = factura.value.created_id
      form.updated_at = factura.value.updated_at
      form.updated_id = factura.value.updated_id
      form.detalleTipo = factura.value.guias_despacho === 'N/A' ? false : true
      detalleTipo.value = factura.value.guias_despacho === 'N/A' ? false : true
      fromDespachos.value = false
    }

    const facturarGuia = () => {
      messages.value = [{content: 'Cargando Datos de la Guia... ', id: lineas.value.length}]
      fromDespachos.value = true
      loading.value = true
      client.get(`/api/despachos/${router.currentRoute.value.params.guia}/guia`).then(response => {
        //
        guia.value = response.data.data.guia
        despachadores.value = response.data.data.despachadores
        fscs.value = response.data.data.fscs
        choferes.value = response.data.data.choferes
        tpagos.value = response.data.data.tpagos
        origenes.value = response.data.data.origenes
        destinos.value = response.data.data.destinos
        productos.value = response.data.data.productos
        porciva.value = response.data.data.porciva
        tipoDocumentos.value = response.data.data.tipoDocumentos
        transportistas.value = response.data.data.transportistas
        clientes.value = response.data.data.clientes
        ///**** */
        if (guia.value !== undefined && guia.value !== null) {
          selectedGuias.value.push(guia)
          lineas.value = []
          factura.value = {}
          facturaDialog.value = true
          form.value = {}
          submitted.value = false
          saving.value = false
          guias.value = []
          codguias.value = []
          selectedGuias.value = []
          messages.value = []

          let detalles = isProxy(guia.value.productos) ? toRaw(guia.value.productos) : []

          lineas.value = detalles.reduce((acc, ln, index) => {
            acc.push({
              'idx': index + 1,
              'producto_obj': {'id': ln.id, 'nombre': ln.nombre, '_nombre': ln.id +'-'+ln.nombre},
              'producto': ln.nombre,
              'observacion': ln.pivot.observacion,
              'espesor': ln.pivot.espesor,
              'ancho': ln.pivot.ancho,
              'largo': ln.pivot.largo,
              'cant_despacho': ln.pivot.cant_despacho,
              'mc_despacho': ln.pivot.mc_despacho,
              'pulg_despacho': ln.pivot.pulg_despacho,
              'cant_recepcion': ln.pivot.cant_recepcion,
              'mc_recepcion': ln.pivot.mc_recepcion,
              'pulg_recepcion': ln.pivot.pulg_recepcion,
              'precio': ln.pivot.precio,
              'tipo_cambio': ln.pivot.tipo_cambio,
              'total': ln.pivot.total,
              'total_mask': ln.pivot.total
            });
            return acc;
          }, [])

          form.origen = toRaw(origenes.value.filter(function (itm) {
            return [guia.value.origen.id].indexOf(itm.id) > -1;
          }))[0]
          form.destino = toRaw(destinos.value.filter(function (itm) {
            return [guia.value.destino.id].indexOf(itm.id) > -1;
          }))[0]
          form.patente_camion = guia.value.patente_camion
          form.patente_carro = guia.value.patente_carro

          form.despachador = toRaw(despachadores.value.filter(function (itm) {
            return [guia.value.despachador.id].indexOf(itm.id) > -1;
          }))[0]
          form.transportista = toRaw(transportistas.value.filter(function (itm) {
            return [guia.value.transportista.id].indexOf(itm.id) > -1
          }))[0]
          form.chofer = toRaw(choferes.value.filter(function (itm) {
            return [guia.value.chofer.id].indexOf(itm.id) > -1
          }))[0]

          form.nro_oc = guia.value.nro_oc
          form.fecha_oc = guia.value.fecha_oc
          form.flete = guia.value.flete

          form.fsc = guia.value.fsc !== null ? toRaw(fscs.value.filter(function (itm) {
            return [guia.value.fsc.id].indexOf(itm.id) > -1;
          }))[0] : null

          form.fsc_codigo = guia.value.fsc_codigo

          form.detalles = lineas.value
          form.cliente = toRaw(clientes.value.filter(function (itm) {
            return [guia.value.cliente.id].indexOf(itm.id) > -1
          }))[0]

          form.poriva = guia.value.poriva
          form.neto = guia.value.neto
          form.iva = guia.value.iva
          form.total = guia.value.total

          form.monto_letras = Number(form.total) > 0 ? writtenNumber(form.total).toUpperCase() : ''
          form.guias_despacho = 'Venta según guía(s): ' + guia.value.folio
          form.guias = [guia.value.id]
        }
      })

      loading.value = false
    }

    const openNew = () => {
      factura.value = {}
      submitted.value = false
      facturaDialog.value = true
      saving.value = false
      lineas.value
      detalleTipo.value = true

      // e = []
      guias.value = []
      codguias.value = []
      selectedGuias.value = []
      messages.value = []

      form.value = {}

      form.cliente = null
      form.tipoDocumento = null
      form.tipoVenta = null
      form.origen = ''
      form.destino = ''
      form.transportista = ''
      form.chofer = ''
      form.despachador = ''
      form.fsc = ''
      form.fsc_codigo = ''
      form.fecha = moment().toDate()
      form.patente_camion = ''
      form.patente_carro = ''
      form.flete = null
      form.poriva = porciva.value
      form.descuento = 0
      form.neto = null
      form.iva = null
      form.total = null
      form.nro_oc = null
      form.fecha_oc = ''
      form.observacion = ''
      form.monto_letras = ''
      form.guias_despacho = ''
      form.detalles = []
      form.tipoPago = null
      form.guias = []
      //para busqueda de guias
      form.fdesde = null
      form.fhasta = null
      form.folio = 0 // se comenta mientras cargan data historica de ENE-24 nextid.value
      form.detalleTipo = true
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteFacturasDialog.value = true
    }

    const confirmDeleteFactura = (emp) => {
      factura.value = emp
      deleteFacturaDialog.value = true
    }

    const hideDialog = () => {
      facturaDialog.value = false
      submitted.value = false
      saving.value = false
      if (fromDespachos.value === true)
        router.push({name: "despachos.index"})
      else fromDespachos.value = false
    }

    const onHideDialogForm = (event) => {
      if (event === false && fromDespachos.value === true)
        router.push({name: "despachos.index"})
      else fromDespachos.value = false
    }

    const searchTipoDocumento = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoDocumentos.value = [...tipoDocumentos.value]
        } else {
          filteredTipoDocumentos.value = tipoDocumentos.value.filter((tdocumento) => {
            return tdocumento._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchCliente = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredClientes.value = [...clientes.value]
        } else {
          filteredClientes.value = clientes.value.filter((cliente) => {
            return cliente._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchDespachador = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredDespachadores.value = [...despachadores.value]
        } else {
          filteredDespachadores.value = despachadores.value.filter((despachador) => {
            return despachador._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchFsc = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredFscs.value = [...fscs.value]
        } else {
          filteredFscs.value = fscs.value.filter((fsc) => {
            return fsc.nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const setFscCodigo = (event) => {
      form.fsc_codigo = event.value.codigo;
    }

    const setTotal = (event) => {
      form.total = 0
      form.total = event.value.codigo_sii == 46 ? form.neto : Math.round(form.neto *1.19)
    }

    const searchChofer = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredChoferes.value = [...choferes.value]
        } else {
          filteredChoferes.value = choferes.value.filter((chofer) => {
            return chofer._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchDestino = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredDestinos.value = [...destinos.value]
        } else {
          filteredDestinos.value = destinos.value.filter((destino) => {
            return destino._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchOrigen = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredOrigenes.value = [...origenes.value]
        } else {
          filteredOrigenes.value = origenes.value.filter((origen) => {
            return origen._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchTransportista = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTransportistas.value = [...transportistas.value]
        } else {
          filteredTransportistas.value = transportistas.value.filter((transportista) => {
            return transportista._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchTPago = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoPagos.value = [...tpagos.value]
        } else {
          filteredTipoPagos.value = tpagos.value.filter((tpago) => {
            return tpago._nombre.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchProducto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProductos.value = [...productos.value]
        } else {
          filteredProductos.value = productos.value.filter((producto) => {
            return producto._nombre.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchTipoVenta = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoVentas.value = [...tipoVentas.value];
        } else {
          filteredTipoVentas.value = tipoVentas.value.filter((tventa) => {
            return tventa.name.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'}).format(value)
    }

    const formatFecha = (value) => {
      return moment(value).format('DD-MM-YY')
    }

    const upperCase = ($event, field) => {
      form[field] = $event.toUpperCase()
    }

    const saveFactura = () => {
      submitted.value = true
      let limite = form.nro_oc === null ? 40 : 39

      if (!v$._value.$invalid) {
        form.guias = fromDespachos.value ? [guia.value] : selectedGuias.value 
        if (lineas.value.length > 0) {
          messages.value = []
          form.detalles = lineas
          if(detalleTipo._value === true){
            form.detalleTipo = true
            if(selectedGuias.value.length <= limite )                  
              storeFactura(form)
            else
              muchasGuias.value = true
          }else{
            if(form.nro_oc === null || form.fecha_oc === '' || form.fecha_oc === null)
              messages.value = [{content: 'Cuando el detalle es por producto, debe indicar los datos de la OC', id: 0}]   
            else{
              detalleTipo.value = false
              form.detalleTipo = false
              storeFactura(form)
            }
          }
          fromDespachos.value = false
          router.push({name: "facturas.index"})
        } else
          messages.value = [{content: 'Debe seleccionar al menos una guia con productos', id: lineas.value.length}]
      }
    }

    const buscarGuia = (event) => {
      messages.value = []
      let cliente = isProxy(form.cliente) ? toRaw(form.cliente) : null
      getGuias(cliente.id, form.fdesde.toLocaleDateString('es-CL'), form.fhasta.toLocaleDateString('es-CL'))
    }

    const onGuiaSelect = (event) => {
      if (fromDespachos.value) {
        selectedGuias.value = []
        selectedGuias.value.push(guia)
      }

      let guiasCliente = toRaw(selectedGuias.value)

      messages.value = [{
        content: 'Se estan seleccionando ' + selectedGuias.value.length + ' guias para facturar',
        id: selectedGuias.value.length
      }]

      form.origen = null
      form.destino = null
      form.patente_camion = null
      form.patente_carro = null
      form.despachador = null
      form.transportista = null
      form.chofer = null
      form.nro_oc = null
      form.fecha_oc = null
      form.flete = null
      form.fsc = null
      form.fsc_codigo = null

      //detalles
      codguias.value = []
      lineas.value = []
      let idx = 0
      $.each(guiasCliente, function (key, guia) {
        let detalles = isProxy(guia.productos) ? toRaw(guia.productos) : []
        codguias.value.push(guia.folio)
        if (detalles.length > 0) {
          detalles.map((ln) => {
            idx++
            lineas.value.push({
              idx: idx,
              producto_obj: { id: ln.id, nombre: ln.nombre },
              producto: (ln.id+'-'+ln.nombre).toString(),
              observacion: ln.pivot.observacion,
              espesor: ln.pivot.espesor,
              ancho: ln.pivot.ancho,
              largo: ln.pivot.largo,
              cant_despacho: ln.pivot.cant_despacho,
              mc_despacho: ln.pivot.mc_despacho,
              pulg_despacho: ln.pivot.pulg_despacho,
              cant_recepcion: ln.pivot.cant_recepcion,
              mc_recepcion: ln.pivot.mc_recepcion,
              pulg_recepcion: ln.pivot.pulg_recepcion,
              precio: ln.pivot.precio,
              tipo_cambio: ln.pivot.tipo_cambio,
              total: ln.pivot.total,
              total_mask: ln.pivot.total,
            })
          })
        }
      })
      totalizaFactura()
    }

    const selAllText = (event) => {
      event.target.setSelectionRange(0, event.srcElement._value.length)
    }

    const setDetalleTipo = (event) => {
      if(detalleTipo._value === true)
        form.guias_despacho = 'Venta según guía(s): ' + codguias.value.join(', ')
      else
        form.guias_despacho = 'N/A'
    }

    const totalizaFactura = () => {
      let guiasCliente = toRaw(selectedGuias.value)
      
      // si se seleccion amas de una guia, se deben agrupar
      if(selectedGuias.value.length > 1){
        let _lineas = groupAndProcessData(lineas.value)
        lineas.value = _lineas
      }
      //calculo de totales
      form.neto = guiasCliente[0] ? lineas.value.reduce((neto, guia) => { 
        return Number(neto) + Number(guia.total)
      }, 0) : 0
      form.iva = guiasCliente[0] ? Math.round(Number(form.neto) * Number(form.poriva) / 100) : 0
      form.total = guiasCliente[0] ? Math.round(Number(form.neto) - Number(form.descuento) + Number(form.iva)) : 0
      form.monto_letras = Number(form.total) > 0 ? writtenNumber(form.total).toUpperCase() : ''

      form.guias_despacho = 'Venta según guía(s): ' + codguias.value.join(', ')
    }
  //Despachos: fecha folio neto iva total nro_oc
  //Detalles: producto observacion espesor ancho largo cant_despacho M3.desp pulg.Desp cant_recepcion M3.recep pulg.recep valor tipo_cambio total


    const onCellEditComplete = (event) => {
      let { data, newValue, field } = event
      data[field] = newValue
      messages.value = []
      let { umedida, m3, pulg, m3r, pulgr } = 0
      let prd = toRaw(data.producto_obj)
      //format values
      data['espesor'] = parseFloat((Math.round(parseFloat(data['espesor']) * 100) / 100).toFixed(2))
      data['ancho'] = parseFloat((Math.round(parseFloat(data['ancho']) * 100) / 100).toFixed(2))
      data['largo'] = parseFloat((Math.round(parseFloat(data['largo']) * 100) / 100).toFixed(2))
      data['cant_despacho'] = parseFloat((Math.round(parseFloat(data['cant_despacho']) * 1000) / 1000).toFixed(3))
      data['cant_recepcion'] = parseFloat((Math.round(parseFloat(data['cant_recepcion']) * 1000) / 1000).toFixed(3))
      data['tipo_cambio'] = parseFloat((Math.round(parseFloat(data['tipo_cambio']) * 1000) / 1000).toFixed(3))

      if(!field.includes('producto')){
        //define la unidad de medida del producto
        $.each(prd, function(key,val) {
          if("/pulgada|metro3|unidad|mm|mruma/".includes(key) && val === 1) umedida = key
        })
        umedida = umedida === undefined ? data['producto_obj']['umedida'] : umedida
        //calcula los m3 y pulgadas
        if(umedida === 'pulgada') {
          // M3 = ((((espesor*ancho*largo)/32)/48.432)*cantidad) * tipo cambio	=    1,577
          m3 = parseFloat(((((data['espesor'] * data['ancho'] * data['largo']) / Number(32)) / parseFloat(48.432).toFixed(3)) * data['cant_despacho']) * data['tipo_cambio'])
          m3r = parseFloat(((((data['espesor'] * data['ancho'] * data['largo']) / Number(32)) / parseFloat(48.432).toFixed(3)) * data['cant_recepcion']) * data['tipo_cambio'])

          // Pulgadas	 =	(((espesor*ancho*largo)/32)*cantidad) * tipo cambio =   76,381
          pulg = parseFloat((((data['espesor'] * data['ancho'] * data['largo']) / Number(32)) *data['cant_despacho']) * data['tipo_cambio'])
          pulgr = parseFloat((((data['espesor'] * data['ancho'] * data['largo']) / Number(32)) *data['cant_recepcion']) * data['tipo_cambio'])

        }else if(umedida === 'mm'){
          // M3 = (((espesor*ancho*largo)/1000000)*cantidad) * tipo cambio		  =    1,920
          m3 = parseFloat((((data['espesor'] * data['ancho'] * data['largo']) / Number(1000000)) * data['cant_despacho']) * data['tipo_cambio'])
          m3r = parseFloat((((data['espesor'] * data['ancho'] * data['largo']) / Number(1000000)) * data['cant_recepcion']) * data['tipo_cambio'])

          // Pulgadas	 =	(((espesor*ancho*largo)/1000000)*cantidad)* tipo cambio =   92,989
          pulg = parseFloat((((data['espesor'] * data['ancho']* data['largo']) / Number(1000000)) * parseFloat(48.432).toFixed(3) * data['cant_despacho']) * data['tipo_cambio'])
          pulgr = parseFloat((((data['espesor'] * data['ancho']* data['largo']) / Number(1000000)) * parseFloat(48.432).toFixed(3) * data['cant_recepcion']) * data['tipo_cambio'])
        }else if(umedida === 'mruma'){
          m3 = parseFloat(parseFloat(0).toFixed(2))
          pulg = parseFloat(parseFloat(0).toFixed(2))
          m3r = parseFloat(parseFloat(0).toFixed(2))
          pulgr = parseFloat(parseFloat(0).toFixed(2))          
        }else {
          m3 = parseFloat(parseFloat(0).toFixed(2))
          pulg = parseFloat(parseFloat(0).toFixed(2))
          m3r = parseFloat(parseFloat(0).toFixed(2))
          pulgr = parseFloat(parseFloat(0).toFixed(2))          
        }

        data['total'] = Number(data['precio']) * Number(data['cant_recepcion'])
        data['total_mask'] = Number(Number(data['total'].toFixed(0)))

        data['mc_despacho'] = parseFloat((Math.round(m3 * 100 ) / 100).toFixed(2))
        data['pulg_despacho'] = parseFloat((Math.round(pulg * 100 ) / 100).toFixed(2))

        data['mc_recepcion'] = parseFloat((Math.round(m3r * 100 ) / 100).toFixed(2))
        data['pulg_recepcion'] = parseFloat((Math.round(pulgr * 100 ) / 100).toFixed(2))

        //agrupa y totaliza las guias y recalcula totales de factura
        totalizaFactura()

      }else
        if(newValue.nombre !== undefined)
          if (newValue.nombre.trim().length > 0) {
            data[field] = newValue.id +'-'+ newValue.nombre
            data['producto_obj'] = newValue
            messages.value = []
          }else event.preventDefault()
    }

    const groupAndProcessData = (data) => {
      //********* version con promedio de precios ******** */
      const groupedData = {}
      // Agrupar los elementos por el id
      data.forEach((item) => {
        let id = item.producto_obj.id+'-'+item.espesor+'-'+item.ancho+'-'+item.largo
        const k = 1
        if (!groupedData[id]) { 
          //Es un producto que no estaba agrupado se agrega al array de agrupados
          groupedData[id] = {...item}
          groupedData[id].count = k
        } else {
          // Sumar las cantidades y totales solo si es el mismo espesor, ancho y largo
          groupedData[id].cant_despacho += item.cant_despacho
          groupedData[id].mc_despacho += item.mc_despacho
          groupedData[id].pulg_despacho += item.pulg_despacho
          groupedData[id].cant_recepcion += item.cant_recepcion
          groupedData[id].mc_recepcion += item.mc_recepcion
          groupedData[id].pulg_recepcion += item.pulg_recepcion

          groupedData[id].total += item.total
          groupedData[id].total_mask = Number(groupedData[id].total).toLocaleString('es-CL')
          groupedData[id].precio += item.espesor === 0 ?  Math.round(item.precio) : Math.round(item.precio/item.cant_despacho)
          groupedData[id].count += k
          groupedData[id].tipo_cambio /= item.tipo_cambio

          groupedData[id].observacion = item.observacion !== undefined ? groupedData[id].observacion + ' ' + item.observacion : ''
        }
      })
      
      Object.values(groupedData).forEach((group) => {
        if(group.espesor === 0){
          group.precio /= group.count;
          group.total = Number(group.precio * group.cant_recepcion * group.tipo_cambio).toFixed(0)
          group.total_mask = group.total.toLocaleString('es-CL')
        }
      })
      // Convertir el objeto en un array y devolverlo
      return Object.values(groupedData);
    }

    const onSelectAllChange = (event) => {
      if (fromDespachos.value === false) {
        if (event.data !== undefined && (event.data.length === guias.value.length)) {
          selectedGuias.value = guias.value
          let guiasCliente = toRaw(selectedGuias.value)
          messages.value = [{
            content: 'Se estan seleccionando ' + selectedGuias.value.length + ' guias para facturar',
            id: selectedGuias.value.length
          }]
          form.origen = null
          form.destino = null
          form.patente_camion = null
          form.patente_carro = null
          form.despachador = null
          form.transportista = null
          form.chofer = null
          form.nro_oc = null
          form.fecha_oc = null
          form.flete = null
          form.fsc = null
          form.fsc_codigo = null

          //detalles
          codguias.value = []
          lineas.value = []
          let idx = 0

          // $.each(guiasCliente, function (key, guia) {
          let key, guia;
          for(key = 0; key < guiasCliente.length; key++){
            guia = guiasCliente[key]
            let detalles = isProxy(guia.productos) ? toRaw(guia.productos) : (guia.productos.length > 0 ? guia.productos : [])
            codguias.value.push(guia.folio)
            let umedida = 0
            if (detalles.length > 0) {
              detalles.map((ln) => {
                idx++
                //define la unidad de medida del producto
                // $.each(ln, function(key,val) {
                let j, val;
                for (j = 0; j < ln.length; j++){
                  val = ln[j]
                  if("/pulgada|metro3|unidad|mm|mruma/".includes(j) && val === 1) umedida = j
                // })  
                }           
                lineas.value.push({
                  idx: idx,
                  producto_obj: { id: ln.id, nombre: ln.nombre, umedida: umedida },
                  producto: (ln.id+'-'+ln.nombre).toString(),
                  observacion: ln.pivot.observacion,
                  espesor: ln.pivot.espesor,
                  ancho: ln.pivot.ancho,
                  largo: ln.pivot.largo,
                  cant_despacho: ln.pivot.cant_despacho,
                  mc_despacho: ln.pivot.mc_despacho,
                  pulg_despacho: ln.pivot.pulg_despacho,
                  cant_recepcion: ln.pivot.cant_recepcion,
                  mc_recepcion: ln.pivot.mc_recepcion,
                  pulg_recepcion: ln.pivot.pulg_recepcion,
                  precio: ln.pivot.precio,
                  tipo_cambio: ln.pivot.tipo_cambio,
                  total: ln.pivot.total,
                  total_mask: ln.pivot.total,
                })
              })
            }
          // })
          }
          totalizaFactura()
        }else if (event.data == undefined){
          lineas.value = []
          guias.value = []
          codguias.value = []
          selectedGuias.value = []
          messages.value = []

          form.descuento = 0
          form.neto = null
          form.iva = null
          form.total = null
          form.monto_letras = ''
          form.guias_despacho = ''
          form.detalles = []
          form.guias = []          
        }
      }
    }

    const v$ = useVuelidate(rules, form)

    if (router.currentRoute.value.params.guia) {
      fromDespachos.value = true
      onMounted(facturarGuia)
    } else {
      fromDespachos.value = false
      onMounted(() => {
        loading.value = true
        lazyParams.value = {
          first: dt.value.first,
          rows: dt.value.rows,
          sortField: null,
          sortOrder: null,
          filters: filters.value
        }
        loadLazyData()
      }) 
    }

    return {
      dt,
      dtg,
      loading,
      saving,
      factura,
      chofer,
      fsc,
      fscs,
      facturas,
      productos,
      lineas,
      codguias,
      columns,
      choferes,
      guias,
      clientes,
      despachadores,
      transportistas,
      filters,
      gfilters,
      selectedFacturas,
      selectedTpago,
      tpagos,
      deleteFacturasDialog,
      deleteFacturaDialog,
      facturaDialog,
      submitted,
      filteredClientes,
      filteredDespachadores,
      filteredFscs,
      filteredChoferes,
      filteredDestinos,
      filteredOrigenes,
      filteredProductos,
      filteredTransportistas,
      filteredTipoPagos,
      selectedProductos,
      selectedGuias,
      porciva,
      v$,
      form,
      messages,
      detalleTipo,
      nextid,
      tipoDocumentos,
      filteredTipoDocumentos,
      tipoVentas,
      selectedTipoVenta,
      filteredTipoVentas,
      fromDespachos,
      hideDialog,
      setFscCodigo,
      openNew,
      confirmDeleteFactura,
      deleteSelectedFacturas,
      deleteFactura,
      exportCSV,
      confirmDeleteSelected,
      searchProducto,
      searchCliente,
      searchDespachador,
      searchFsc,
      searchChofer,
      searchDestino,
      searchTransportista,
      searchOrigen,
      searchTPago,
      editFactura,
      saveFactura,
      storeFactura,
      formatCurrency,
      formatFecha,
      upperCase,
      buscarGuia,
      onGuiaSelect,
      onCellEditComplete,
      searchTipoDocumento,
      searchTipoVenta,
      facturarGuia,
      onHideDialogForm,
      selAllText,
      groupAndProcessData,
      totalizaFactura,
      totalRecords,
      first,
      gfirst,
      lazyParams,
      lazyParamsGuia,
      loadLazyData,
      onPage,
      onSort,
      onFilter,   
      getPDFSII,
      onSelectAllChange,
      setDetalleTipo,
      initFilters,
      muchasGuias,
      setTotal
    }
  },
}
</script>
