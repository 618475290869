import { ref, toRaw } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'
import { useRouter } from 'vue-router'

export default function useFacturas() {

  const factura = ref([])
  const guia = ref([])
  const chofer = ref([])
  const fsc = ref([])
  const facturas = ref([])
  const choferes = ref([])
  const guias = ref([])
  const fscs = ref([])
  const facturasDelete = ref([])
  const es_venta = ref()
  const porciva = ref()
  const nextid = ref()
  const messages = ref([])
  const router = useRouter()

  const client = useClient()

  const clientes = ref([])
  const despachadores = ref([])
  const tipoDocumentos = ref([])
  const destinos = ref([])
  const origenes = ref([])
  const transportistas = ref([])
  const productos = ref([])
  const selectedProductos = ref([])
  const selectedGuias = ref([])
  const lineas = ref([])
  const codguias = ref([])
  const fromDespachos = ref(false)
  const first = ref(0)
  const gfirst = ref(0)  
  const lazyParams = ref({})
  const lazyParamsGuia = ref({})
  const totalRecords = ref(0)

  const tipoVentas = ref([
    {id: 0, nombre: "Seleccione"},
    {id: 1, nombre: "1-Subproducto"},
    {id: 2, nombre: "2-Madera"},
    {id: 3, nombre: "3-Otro"}
  ])

  const detalleTipo = ref(true)
  const selectedTpago = ref([])
  const tpagos = ref([])

  const columns = ref([
    { field: 'idx', header: 'Ln.', hidden: false, size: '1%'},
    { field: 'producto_obj', header: 'Cod.', hidden: true, size:'width: 0%'},
    { field: 'producto', header: 'Producto', hidden: false, size:'width: 15%'},
    { field: 'observacion', header: 'Observ.', hidden: false, size:'width: 15%'  },
    { field: 'espesor', header: 'Espesor', hidden: false, size:'width: 4%'},
    { field: 'ancho', header: 'Ancho', hidden: false, size:'width: 4%'},
    { field: 'largo', header: 'Largo', hidden: false, size:'width: 4%'},
    { field: 'cant_despacho', header: 'Cant.Despacho', hidden: false, size:'width: 10%'},
    { field: 'mc_despacho', header: 'M3.Despacho', hidden: false, size:'width: 4%'},
    { field: 'pulg_despacho', header: 'Pulg.Despacho', hidden: false, size:'width: 4%'},
    { field: 'cant_recepcion', header: 'Cant.Recep', hidden: false, size:'width: 10%'},
    { field: 'mc_recepcion', header: 'M3.Recep', hidden: false, size: 'width: 4%'},
    { field: 'pulg_recepcion', header: 'Pulg.Recep', hidden: false, size:'width: 4%'},
    { field: 'precio', header: 'Valor', hidden: false, size:'width: 10%'},
    { field: 'tipo_cambio', header: 'Tipo Cambio', hidden: false , size:'width: 1%'},
    { field: 'total', header: 'Total', hidden: true, size:'width: 0%'},
    { field: 'total_mask', header: 'Total', hidden: false, size: 'width: 10%'}
  ])

  const dt = ref({})
  const dtg = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedFacturas = ref()
  const selectedTipoVenta= ref()
  const deleteFacturasDialog = ref(false)
  const deleteFacturaDialog = ref(false)
  const facturaDialog = ref(false)
  const submitted = ref(false)

  const filteredClientes = ref()
  const filteredDespachadores = ref()
  const filteredFscs = ref()
  const filteredChoferes = ref()
  const filteredTipoPagos = ref()
  const filteredDestinos = ref()
  const filteredOrigenes = ref()
  const filteredProductos = ref()
  const filteredTransportistas = ref()
  const filteredTipoDocumentos = ref()
  const filteredTipoVentas = ref()

  const storeFactura = async (form) => {
    saving.value = true
    let id = factura.value.id

    //Esta creando el registro
    client.post(`/api/facturas`, { 'data': { ...form } })
    .then((response) => {
      if(response.status === 200){
        facturaDialog.value = client.dialog.value
        factura.value = client.entity.value
        saving.value = client.saving.value
        loadLazyData()
      }else{
        //Mostrar razon del error y habilita de nuevo el boton guardar
        facturaDialog.value = true
        factura.value = client.entity.value
        saving.value = false          
      }
    })
  }

  const deleteFactura = async () => {
    client.remove(`/api/facturas/${factura.value.id}`)
      .then(response => {
        facturas.value = facturas.value.filter(val => val.id !== factura.value.id)
        deleteFacturaDialog.value = false
        factura.value = {}
      })
  }

  const deleteSelectedFacturas = async () => {
    facturasDelete.value = selectedFacturas.value.map(({ id }) => id)
    client.remove(`/api/facturas/destroy/mass`, { data: facturasDelete.value })
      .then(response => {
        facturas.value = facturas.value.filter(
          (val) => !selectedFacturas.value.includes(val)
        )
        deleteFacturasDialog.value = false
        selectedFacturas.value = null
        facturasDelete.value = null
      })
  }

  const getGuias = async (id, fdesde, fhasta) => {    
    client.get(`/api/despachos/${id}/${fdesde}/${fhasta}/guias`).then(response => {
      guias.value = response.data.data.guias
      fscs.value = response.data.data.fscs
      loading.value = false
    })
  }

  const loadLazyData = (event) => {
    lazyParams.value = { ...lazyParams.value, first: event?.first || first.value }

    client.get(`/api/facturas/all/${lazyParams.value.first}/${lazyParams.value.rows}`, { data: lazyParams.value }).then(response => {
      facturas.value = response.data.data.facturas
      despachadores.value = response.data.data.despachadores
      fscs.value = response.data.data.fscs
      choferes.value = response.data.data.choferes
      tipoDocumentos.value = response.data.data.tipoDocumentos
      tpagos.value = response.data.data.tpagos
      destinos.value = response.data.data.origenes
      origenes.value = response.data.data.origenes
      productos.value = response.data.data.productos
      clientes.value = response.data.data.clientes
      transportistas.value = response.data.data.transportistas
      porciva.value = response.data.data.porciva
      clientes.value = response.data.data.clientes
      transportistas.value = response.data.data.transportistas

      totalRecords.value = response.data.data.totalRecords
      
      // getNextFolio(4)
      nextid.value = 0

      loading.value = false
    })    
    .catch(function (error) { 
      loading.value = false
    })         
  }

  const onPage = (event) => {
    lazyParams.value = event
    loadLazyData(event)
  }

  const onSort = (event) => {
    lazyParams.value = event
    loadLazyData(event)
  }

  const onFilter = (event) => {
    lazyParams.value.filters = filters.value 
    loadLazyData(event)
  }

  const getPDFSII = async (d) => {
    loading.value = true
    let tdoc = d.tipoDocumento ? toRaw(tipoDocumentos.value.filter(function (itm) { return [parseInt(d.tipoDocumento.id)].indexOf(itm.id) > -1 }))[0] : null
    
    let rut_dv = '76492850-4'

    if(d.folio !== undefined)
      axios.get(`/api/facturas/${tdoc.codigo_sii}/${d.folio}/E/${rut_dv}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }
  
  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'tipoDocumento': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
    'fecha': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'nro_oc': { value: null, matchMode: FilterMatchMode.EQUALS },   
    'rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },    
    'cliente': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'origen': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'destino': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'transportista': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'guias_despacho': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'neto': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'iva': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'total': { value: null, matchMode: FilterMatchMode.EQUALS },  
  })

  const gfilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    fecha: { value: null, matchMode: FilterMatchMode.EQUALS },  
    folio: { value: null, matchMode: FilterMatchMode.EQUALS },
    nro_oc: { value: null, matchMode: FilterMatchMode.EQUALS },
    neto: { value: null, matchMode: FilterMatchMode.EQUALS },  
    iva: { value: null, matchMode: FilterMatchMode.EQUALS },  
    total: { value: null, matchMode: FilterMatchMode.EQUALS },  
  })

  const initFilters = () => {
    filters.value = {
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    
      'tipoDocumento': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
      'fecha': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'nro_oc': { value: null, matchMode: FilterMatchMode.EQUALS },   
      'rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },    
      'cliente': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'origen': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'destino': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'transportista': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'guias_despacho': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'neto': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'iva': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'total': { value: null, matchMode: FilterMatchMode.EQUALS }, 
    }
    lazyParams.value.filters = filters.value
    loadLazyData()
  }

  return {
    factura,
    fsc,
    chofer,
    es_venta,
    porciva,
    facturas,
    fscs,
    choferes,
    guias,
    clientes,
    despachadores,
    tipoDocumentos,
    nextid,
    destinos,
    origenes,
    first,
    gfirst,
    dt,
    dtg,
    loading,
    saving,
    gfilters,
    filters,
    initFilters,
    selectedFacturas,
    selectedTpago,
    tpagos,
    deleteFacturasDialog,
    deleteFacturaDialog,
    facturaDialog,
    submitted,
    filteredClientes,
    filteredFscs,
    filteredDespachadores,
    filteredTipoDocumentos,
    filteredChoferes,
    filteredTipoPagos,
    filteredDestinos,
    filteredOrigenes,
    filteredProductos,
    filteredTransportistas,
    selectedProductos,
    selectedGuias,
    productos,
    transportistas,
    lineas,
    codguias,
    columns,
    messages,
    detalleTipo,
    deleteSelectedFacturas,
    getGuias,
    storeFactura,
    deleteFactura,
    tipoVentas,
    selectedTipoVenta,
    filteredTipoVentas,
    guia,
    router,
    fromDespachos,
    totalRecords,
    lazyParams,
    lazyParamsGuia,
    loadLazyData,
    onPage,
    onSort,
    onFilter,
    getPDFSII
  }
}
