<template>
  <Toast />
  <div class="card-header">
    <Toolbar class="mb-0">
      <template #start>
        <h4>Dashboard</h4>
      </template>
      <template #end>
        <label>Período</label>&nbsp;
        <Calendar id="periodo" aria-describedby="periodo-error" v-model="periodo.label" inputClass="bg-input"
          autocomplete="off" view="month" dateFormat="mm/yy" :showIcon="true" @date-select="selectPeriodo" />&nbsp;&nbsp;
      </template>
    </Toolbar>
  </div>
  <div class="card-body dashboard mt-2">
    <!-- Gráfico -->
    <div class="row">
      <div class="col-lg-2" style="zoom: 1.06;">
        <div class="card shadow-1 flex flex-column mb-2" style="border-color: #28a745; border-left-width: 4px; border-left-style: solid;background: #FFF;"  @click="edoVtasDet('PAGADA')">
          <div class="row mt-1">
            <div class="flex align-items-center col-10">
              <h5 class="card-title ms-1 mt-1" style="color: #28a745;">VENTAS PAGADAS</h5>
            </div>
          </div>
          <div class="row mt-0">
            <div class="flex align-items-center col-5" style="text-align: center">
              <p class="card-title h4 mt-0 mb-1 me-1" style="text-align: center">{{ (kpiEdosVtas.pacant_docs) }}</p>
              <small>Documentos</small>
            </div>
            <div class="flex align-items-center col-7 mb-1" style="text-align: center;border-left-width: 1px; border-left-style: solid;border-color: #e3e4e5; " >
              <p class="card-title h4 mt-0 mb-1 me-1" style="text-align: center">${{ formatCurrency(kpiEdosVtas.patotal, false) }}</p>
              <small>Monto (CLP)</small>
            </div>            
          </div>          
        </div>
        <div class="card shadow-1 flex flex-column mb-2" style="border-color: #c89551; border-left-width: 4px; border-left-style: solid;background: #FFF;"  @click="edoVtasDet('PENDIENTE')">
          <div class="row mt-1">
            <div class="flex align-items-center col-10">
              <h5 class="card-title ms-1 mt-1" style="color: #c89551;">VENTAS PENDIENTES</h5>
            </div>
          </div>
          <div class="row mt-0">
            <div class="flex align-items-center col-5" style="text-align: center">
              <p class="card-title h4 mt-0 mb-1 me-1" style="text-align: center">{{ kpiEdosVtas.pecant_docs }}</p>
              <small>Documentos</small>
            </div>
            <div class="flex align-items-center col-7 mb-1" style="text-align: center;border-left-width: 1px; border-left-style: solid;border-color: #e3e4e5; " >
              <p class="card-title h4 mt-0 mb-1 me-1" style="text-align: center">${{ formatCurrency(kpiEdosVtas.petotal, false) }}</p>
              <small>Monto (CLP)</small>
            </div>            
          </div>          
        </div>
        <div class="card shadow-1 flex flex-column mb-2" style="border-color: #ef4444; border-left-width: 4px; border-left-style: solid;background: #FFF;"  @click="edoVtasDet('VENCIDA')">
          <div class="row mt-1">
            <div class="flex align-items-center col-10">
              <h5 class="card-title ms-1 mt-1" style="color:#ef4444;">VENTAS VENCIDAS</h5>
            </div>
          </div>
          <div class="row mt-0">
            <div class="flex align-items-center col-5" style="text-align: center">
              <p class="card-title h4 mt-0 mb-1 me-1" style="text-align: center">{{ kpiEdosVtas.vecant_docs }}</p>
              <small>Documentos</small>
            </div>
            <div class="flex align-items-center col-7 mb-1" style="text-align: center;border-left-width: 1px; border-left-style: solid;border-color: #e3e4e5; " >
              <p class="card-title ms-1 h4 mt-0 mb-1 me-1" style="text-align: center">${{ formatCurrency(kpiEdosVtas.vetotal, false) }}</p>
              <small>Monto (CLP)</small>
            </div>            
          </div>          
        </div>
        <div class="card shadow-1 flex flex-column" style="border-color: rgb(100, 181, 246); border-left-width: 4px; border-left-style: solid;background: #FFF;"  @click="edoVtasDet('SESTADO')">
          <div class="row mt-1">
            <div class="flex align-items-center col-10">
              <h5 class="card-title ms-1 mt-1" style="color:rgb(100, 181, 246);">VENTAS SIN ESTADO</h5>
            </div>
          </div>
          <div class="row mt-0">
            <div class="flex align-items-center col-5" style="text-align: center">
              <p class="card-title h4 mt-0 mb-1 me-1" style="text-align: center">{{ kpiEdosVtas.secant_docs }}</p>
              <small>Documentos</small>
            </div>
            <div class="flex align-items-center col-7 mb-1" style="text-align: center;border-left-width: 1px; border-left-style: solid;border-color: #e3e4e5; " >
              <p class="card-title h4 mt-0 mb-1 me-1" style="text-align: center">${{ formatCurrency(kpiEdosVtas.setotal, false) }}</p>
              <small>Monto (CLP)</small>
            </div>            
          </div>          
        </div>
      </div>
      <div class="col-lg-6 grid-margin stretch-card" style="zoom: 1;">
        <div class="card shadow-1 flex flex-column" :style="card_style">
          <DataTable :value="kpiEdosVtasDet" scrollable scrollHeight="540px" tableStyle="min-width: 100%" 
            style="zoom: 0.80;">
            <template #empty> Sin Registros. </template>
            <template #loading> Cargando registros. </template>           
            <Column  sortable sortablefield="rut_cliente" header="CLIENTE" style="width:55%;color:#000;" :headerStyle="style_estado">
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.rut_cliente + ' ' +  slotProps.data.razon_social }}
                </span>
              </template>
            </Column>            
            <Column  sortable sortablefield="folio" header="FOLIO" style="width:5%;color:#000;" :headerStyle="style_estado">
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.folio }}
                </span>
              </template>
            </Column>            
            <Column  sortable field="total" header="TOTAL" style="text-align:right;" :headerStyle="style_estado">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.total, true) }}
                </span>
              </template>              
            </Column> 
            <Column  sortable field="monto_abono" header="PAGOS" style="text-align:right;" :headerStyle="style_estado">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_abono, true) }}
                </span>
              </template>              
            </Column> 
            <Column  sortable field="saldo" header="SALDO" style="text-align:right;" :headerStyle="style_estado">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.saldo, true) }}
                </span>
              </template>              
            </Column> 
            <ColumnGroup type="footer" v-if="kpiEdosVtasDet.length > 0">
              <Row>
                <Column colspan="2" :footer="'TOTAL VENTAS ' + lestado" :style="style_estado" footerStyle="text-align:right"/>
                <Column :footer="totTotal" :style="style_estado"  footerStyle="text-align:right"/>
                <Column :footer="totPagos" :style="style_estado"  footerStyle="text-align:right"/>
                <Column :footer="totSaldos" :style="style_estado"  footerStyle="text-align:right"/>
              </Row>
            </ColumnGroup>                           
          </DataTable>     
        </div>
      </div>
      <div class="col-lg-4 grid-margin stretch-card" style="zoom: 1;">
        <div class="card shadow-1 flex flex-column" style="color:#000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;">
          <DataTable :value="kpiVtaUm" scrollable scrollHeight="540px" tableStyle="min-width: 100%" 
            style="zoom: 0.80;">
            <template #empty> Sin Registros. </template>
            <template #loading> Cargando registros. </template>           
            <Column  sortable field="producto" header="PRODUCTO" style="width:60%;color:#000 !important;" headerStyle="background: #ccc !important;color:#000 !important;">
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.producto }}
                </span>
              </template>
            </Column>
            <Column  sortable field="umedida" header="U.MEDIDA" style="width:15%;text-align:center;" headerStyle="background: #ccc !important;color:#000 !important;">
              <template #body="slotProps">
                <Badge class="text-uppercase" :severity="umedidaColor(slotProps.data.umedida)">{{ slotProps.data.umedida }}</Badge>
              </template>  
            </Column>           
            <Column  sortable field="cantidad" header="CANT." style="text-align:right;" headerStyle="background: #ccc !important;color:#000 !important;">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.cantidad, false) }}
                </span>
              </template>              
            </Column> 
            <Column  sortable field="monto" header="MONTO" style="text-align:right;" headerStyle="background: #ccc !important;color:#000 !important;">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto, true) }}
                </span>
              </template>              
            </Column> 
            <ColumnGroup type="footer" v-if="kpiVtaUm.length > 0">
              <Row>
                <Column :footer="'TOTAL'" colspan="3" style="background: #ccc !important;color:#000 !important;" footerStyle="text-align:right"/>
                <Column :footer="totTotalUM" style="background: #ccc !important;color:#000 !important;"  footerStyle="text-align:right"/>
              </Row>
            </ColumnGroup>                           
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body dashboard">
    <div class="row">
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card shadow-1 flex flex-column mb-2" style="color:#000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;">
          <div class="card-body" style="padding: 0px;">
            <div v-if="spin.kpiprod">
              <ProgressSpinner style="width:30px;height:30px" strokeWidth="6" />
            </div>
            <div v-else>
              <CanvasJSChart :options="kpiRecMnt"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card shadow-1 flex flex-column mb-2" style="color:#000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;">
          <div class="card-body" style="padding: 0px;">
            <div v-if="spin.kpigastos">
              <ProgressSpinner style="width:30px;height:30px" strokeWidth="6" />
            </div>
            <div v-else>
              <CanvasJSChart :options="kpiGastos"/>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>
  <div class="card-body dashboard">
    <div class="row">
      <div class="col-lg-4 grid-margin stretch-card">
        <div class="card shadow-1 flex flex-column mb-2" style="color:#000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;">
          <div class="card-body" style="padding: 0px;">
            <div v-if="spin.kpirecpago">
              <ProgressSpinner style="width:30px;height:30px" strokeWidth="6" />
            </div>
            <div v-else>
              <CanvasJSChart :options="kpiRecPago"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 grid-margin stretch-card" style="zoom: 1;height:362px">
        <div class="card shadow-1 flex flex-column" style="color:#000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;">
          <div class="card-body" style="padding: 0px;">
            <div v-if="spin.kpiprod">
              <ProgressSpinner style="width:30px;height:30px" strokeWidth="6" />
            </div>
            <div v-else>
              <CanvasJSChart :options="kpiProdCant"/>
              <div class="mt-n5">
                <h5 class="ms-2 mb-1">Promedio Diario: </h5>
                <span v-for="(item, index) in kpiProdProm" :key="index" class="ms-2 mr-20">
                  <Badge :value="item.label" :severity="index > 0 ? 'success' : 'info'" class="text-uppercase"></Badge>&nbsp;                 
                  <span class="font-medium h5">{{ `${item.y ?? 0}` }}</span>
                </span>                
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div class="col-lg-4 grid-margin stretch-card">
        <div class="card shadow-1 flex flex-column mb-2" style="color:#000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;">
          <div class="card-body" style="padding: 0px;">
            <div v-if="spin.kpicedop">
              <ProgressSpinner style="width:30px;height:30px" strokeWidth="6" />
            </div>
            <div v-else>
              <CanvasJSChart :options="kpiCompraEdo"/>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
import useDashboards from '../../composables/home/dashboards';
import { ref, onMounted, computed } from 'vue';
import moment from 'moment'

export default {
  setup() {
    const {
      style_estado, 
      loading, 
      router,
      spin,
      kpiProdChart,
      kpiProdCant,
      kpiProdProm,
      kpiEdosVtasGet,
      kpiEdosVtas,
      kpiEdosVtasDet,
      kpiEdoVtasDetalles,
      kpiVtaUmedida,
      kpiVtaUm,
      kpiRecepProdMonto,
      kpiRecMnt,
      kpiRecPago,
      kpiRecepPago,
      kpiGastos,
      kpiClasifGastos,
      kpiCompraEdoPago,
      kpiCompraEdo

    } = useDashboards()

    const userLogged = window.Laravel.user
    const periodo = ref({})
    const lestado = ref('')
    const card_style = ref("color: #000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;")

    let periodoSelected =  new Date()
    let _periodo

    const dPeriodo = () => {
      return router.currentRoute.value.params.periodo 
        ? router.currentRoute.value.params.periodo 
        : Number(new Date().getFullYear() + ('0' + (new Date().getMonth() + 1).toString()).slice(-2))
    }

    const selectPeriodo = (e) => {
      periodo.value.val = e !== undefined ? e.getFullYear() + ('0' + (e.getMonth() + 1).toString()).slice(-2) : dPeriodo();
      periodo.value.label = [periodo.value.val.toString().slice(-2), "/", periodo.value.val.toString().slice(0, 4)].join('');

      console.info('periodo: ---->>>', periodo)
      let arrDate = periodo.value.label.split("/");
      
      periodoSelected.setDate("01");
      periodoSelected.setMonth(arrDate[0].toString());
      periodoSelected.setFullYear(arrDate[1].toString());
      _periodo = periodo.value.val;

      obtenerIndicadores()
    }

    const formatCurrency = (value, style = true) => {
      return style  ? new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(Number(value))
                    : new Intl.NumberFormat('es-CL').format(Number(value))
    }

    const formatFecha = (value) => {
      return moment(value).format("DD-MM-YY")
    }

    const obtenerIndicadores = async() => {
      await kpiProdChart(periodo.value.label)
      await kpiEdosVtasGet(periodo.value.label)
      await kpiEdoVtasDetalles(periodo.value.label, '0')
      await kpiVtaUmedida(periodo.value.label)
      await kpiRecepProdMonto(periodo.value.label)
      await kpiRecepPago(periodo.value.label)
      await kpiClasifGastos(periodo.value.label)
      await kpiCompraEdoPago(periodo.value.label)
    }

    const edoVtasDet = async(estado) => {
      switch (estado) {
        case "PAGADA":
          lestado.value = "PAGADA"
          style_estado.value = "background: #28a745 !important;color:#FFF;"
          card_style.value = "color:#000 !important;border-color: #28a745; border-left-width: 4px; border-left-style: solid;background: #FFF;"
          break; !important
        case "PENDIENTE":
          lestado.value = "PENDIENTE"
          style_estado.value = "background: #c89551 !important;color:#000 !important;"
          card_style.value = "color:#000 !important;border-color: #c89551; border-left-width: 4px; border-left-style: solid;background: #FFF;"
          break;
        case "VENCIDA":
          lestado.value = "VENCIDA"
          style_estado.value = "background: #ef4444 !important;color:#FFF !important;"
          card_style.value = "color:#000 !important;border-color: #ef4444; border-left-width: 4px; border-left-style: solid;background: #FFF;"
          break;
        case "SESTADO":
          lestado.value = "SIN ESTADO"
          style_estado.value = "background: rgb(100, 181, 246) !important;color:#FFF !important;"
          card_style.value = "color:#000 !important;border-color: rgb(100, 181, 246); border-left-width: 4px; border-left-style: solid;background: #FFF;"
          break; 
        default:
          lestado.value = ""
          style_estado.value = "background: #ccc !important;color:#000 !important;"
          card_style.value = "color:#000 !important;border-color: #ccc; border-left-width: 4px; border-left-style: solid;background: #FFF;"
      }
      await kpiEdoVtasDetalles(periodo.value.label, estado)
    }

    const umedidaColor = (umedida) => {
      switch (umedida) {
        case "METRO3":
          return 'info'
        case "PULGADA":
          return 'success'
        case "MRUMA":
          return 'warning'
        case "UNIDAD":
          return 'danger'
        case "MM":
          return 'contrast'
        default:
          return 'secondary'
      }
    }         

    const totPagos = computed(() => {
      let monto_abono = 0;
      if(kpiEdosVtasDet.value)
        for(let ln of kpiEdosVtasDet.value)
          monto_abono += Number(ln.monto_abono)
      return formatCurrency(monto_abono, false)
    })      

    const totSaldos = computed(() => {
      let saldo = 0;
      if(kpiEdosVtasDet.value)
        for(let ln of kpiEdosVtasDet.value)
          saldo += Number(ln.saldo)
      return formatCurrency(saldo, false)
    })      

    const totTotal = computed(() => {
      let tcantidad = 0;
      if(kpiEdosVtasDet.value)
        for(let ln of kpiEdosVtasDet.value)
          tcantidad += Number(ln.total)
      return formatCurrency(tcantidad, false)
    })      

    const totTotalUM = computed(() => {
      let tcantidad = 0;
      if(kpiVtaUm.value)
        for(let ln of kpiVtaUm.value)
          tcantidad += Number(ln.monto)
      return formatCurrency(Math.round(tcantidad), false)
    })      

    const totCantidadUM = computed(() => {
      let tcantidad = 0;
      if(kpiVtaUm.value)
        for(let ln of kpiVtaUm.value)
          tcantidad += Number(ln.cantidad)
      return formatCurrency(tcantidad, false)
    })    

    onMounted(selectPeriodo)

    return {
      lestado,
      style_estado, 
      card_style,
      loading,        
      spin,
      userLogged,
      periodo,
      dPeriodo,
      selectPeriodo,
      periodoSelected,
      kpiProdChart,
      kpiProdCant,
      kpiProdProm,
      kpiEdosVtasGet,
      kpiEdosVtas,
      kpiEdosVtasDet,
      formatCurrency,
      formatFecha,
      kpiEdoVtasDetalles,
      edoVtasDet,
      totTotal,
      totCantidadUM,
      totTotalUM,
      kpiVtaUmedida,
      kpiVtaUm,
      umedidaColor,
      kpiRecepProdMonto,
      kpiRecMnt,
      kpiRecPago,
      kpiGastos,
      kpiClasifGastos,
      kpiCompraEdoPago,
      kpiCompraEdo,
      totPagos,
      totSaldos
    }
  }
}
</script>