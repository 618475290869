<template>
  <Toast />
  <div class="card-header row">
    <h4 class="col-md-10">Listado de Recepciones</h4>
    <div class="col-md-2" style="text-align:right;"><Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" /></div>
    <div class="line_title mt-1 ms-3 col-md-1"></div>
  </div>
  <div class="card-body">
    <div>
      <!-- DT Recepciones -->
      <DataTable ref="dt" :value="recepciones" lazy paginator :first="first" :loading="loading" 
        removableSort sortMode="multiple" @sort="onSort($event)" 
        :rows="10" :totalRecords="totalRecords" @page="onPage($event)" @filter="onFilter($event)" 
        v-model:filters="filters" :globalFilterFields="['id','tipoMadera','tipoDocRecep', 'folio_doc_recep', 'rut_dv', 'proveedor', 'fecha', 'fecha_doc_recep', 'origen', 'destino','transportista','neto', 'iva', 'total']" filterDisplay="row" tableStyle="min-width: 75rem" breakpoint="990px" style="zoom: 0.80;" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros">           

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." @keydown.enter="onFilter($event)"/>
                </span>
              </div>
            </template>
            <template #empty> Sin registros. </template>
            <template #loading> Cargando registros. </template>
            <template #end>
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" outlined @click="initFilters()" class="p-button-secondary"/>&nbsp;
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
            </template>
          </Toolbar>
        </template>

        <Column header="Duplicar" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-clone" class="p-button-rounded p-button-primary" 
              @click="duplicarGuia(slotProps.data)" title="Duplicar Recepción" />
          </template>
        </Column>     

        <Column field="id" header="Cod."  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.id }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>
        </Column>       

        <Column field="tipoMadera" header="Tipo" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.tipoMadera.nombre.toUpperCase()" severity="info" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>                  
        </Column>    

        <Column field="fecha" header="Fecha"  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.fecha }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="tipoDocRecep" header="TipoDoc" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.tipoDocRecep.codigo" :severity="slotProps.data.tipoDocRecep.codigo === 'GD' ? 'info' : 'warning'" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>                    
        </Column>           

        <Column field="folio_doc_recep" header="Folio"  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio_doc_recep }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="fecha_doc_recep" header="F.Doc.Recep"  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.fecha_doc_recep }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>  

        <Column field="rut_dv" header="RUT" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.proveedor.rut_dv }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="proveedor" header="Proveedor" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.proveedor.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="origen" header="Origen" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.origen.descripcion.slice(0, 23) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="destino" header="Destinos" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.destino.descripcion.slice(0, 23) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="transportista" header="Transportista" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.transportista.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="neto" header="Neto" sortable>
          <template #body="slotProps">
            <span>
              {{ parseInt(slotProps.data.neto).toLocaleString('es-CL') }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>            

        <Column field="iva" header="IVA" sortable>
          <template #body="slotProps">
            <span>
              {{ parseInt(slotProps.data.iva).toLocaleString('es-CL') }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>            

        <Column field="total" header="Total" sortable>
          <template #body="slotProps">
            <span>
              {{ parseInt(slotProps.data.total).toLocaleString('es-CL') }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>            

        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info"
              @click="getPDFSII(slotProps.data)" title="Ver" />&nbsp;
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editRecepcion(slotProps.data)" />&nbsp;
            <Button icon="pi pi-print" class="p-button-rounded p-button-success"
              @click="recepcionPDF(slotProps.data.id)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteRecepcion(slotProps.data)"  v-if="[0, 1].includes(userRole)"/>
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- Formulario Recepciones en modal -->
    <Dialog v-model:visible="recepcionDialog" :style="{ width: '50%' }" header="Datos de la Recepcion" :modal="true"
      class="p-fluid" :maximizable="true">
      <Divider align="left" type="solid">
        <b class="text-muted">Antecedentes Recepcion</b>
      </Divider>
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-2 field">
            <label for="tipoMadera" class="mt-3">Tipo Recepción</label>
            <AutoComplete id="tipoMadera" v-model="v$.tipoMadera.$model" 
              :class="{ 'p-invalid': v$.tipoMadera.$invalid && submitted }" :suggestions="filteredTipoMaderas"
              @complete="searchTipoMadera($event)" field="_nombre" :dropdown="true" aria-describedby="tipoMadera-error" 
              autofocus autocomplete="off" 
              @change="setProductos($event, index)"/>
            <span v-if="v$.tipoMadera.$error && submitted">
              <span id="tipoMadera-error" v-for="(error, index) of v$.tipoMadera.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.tipoMadera.$invalid && submitted) || v$.tipoMadera.$pending.$response)"
              class="p-error">
              {{ v$.tipoMadera.required.$message.replace('Value', 'Tipo Recepción') }}</small>
          </div> 
          <div class="col-md-2 field">
            <label for="fecha" class="mt-3">Fecha</label>
            <Calendar id="fecha" v-model="v$.fecha.$model" 
              :class="{ 'p-invalid': v$.fecha.$invalid && submitted }" aria-describedby="fecha-error"
              autocomplete="off" :showIcon="true" dateFormat="dd/mm/yy"/>
            <span v-if="v$.fecha.$error && submitted">
              <span id="fecha-error" v-for="(error, index) of v$.fecha.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.fecha.$invalid && submitted) || v$.fecha.$pending.$response"
              class="p-error">
              {{ v$.fecha.required.$message.replace('Value', 'Fecha') }}</small>
          </div>          
          <div class="col-md-2 field">
            <label for="nextid" class="mt-3">Nro.Recepción</label>
            <InputNumber id="nextid" v-model.trim="form.nextid"/>
          </div>                             
          <div class="col-md-2 field">
            <label for="tipoDocRecep" class="mt-3">Recepción Con</label>
            <AutoComplete id="tipoDocRecep" v-model="v$.tipoDocRecep.$model"
              :class="{ 'p-invalid': v$.tipoDocRecep.$invalid && submitted }" :suggestions="filteredTipoDocReceps"
              @complete="searchTipoDocRecep($event)" field="_nombre" :dropdown="true" forceSelection
              aria-describedby="tipoDocRecep-error" autofocus autocomplete="off" />
            <span v-if="v$.tipoDocRecep.$error && submitted">
              <span id="tipoDocRecep-error" v-for="(error, index) of v$.tipoDocRecep.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.tipoDocRecep.$invalid && submitted) || v$.tipoDocRecep.$pending.$response)"
              class="p-error">
              {{ v$.tipoDocRecep.required.$message.replace('Value', 'Tipo Documento') }}</small>
          </div>
          <div class="col-md-2 field">
            <label for="folio_doc_recep" class="mt-3">Nro. Documento</label>
            <InputNumber id="folio_doc_recep" v-model="v$.folio_doc_recep.$model" :class="{ 'p-invalid': v$.folio_doc_recep.$invalid && submitted }"
              aria-describedby="folio_doc_recep-error" autofocus autocomplete="off" />
            <span v-if="v$.folio_doc_recep.$error && submitted">
              <span id="folio_doc_recep-error" v-for="(error, index) of v$.folio_doc_recep.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.folio_doc_recep.$invalid && submitted) || v$.folio_doc_recep.$pending.$response" class="p-error">
              {{ v$.folio_doc_recep.required.$message.replace('Value', 'Valor') }}</small>
          </div>
          <div class="col-md-2 field">
            <label for="fecha_doc_recep" class="mt-3">Fecha.Docto</label>
            <Calendar id="fecha_doc_recep" v-model="v$.fecha_doc_recep.$model" 
              :class="{ 'p-invalid': v$.fecha_doc_recep.$invalid && submitted }" aria-describedby="fecha_doc_recep-error"
              autocomplete="off" :showIcon="true" dateFormat="dd/mm/yy"/>
            <span v-if="v$.fecha_doc_recep.$error && submitted">
              <span id="fecha_doc_recep-error" v-for="(error, index) of v$.fecha_doc_recep.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.fecha_doc_recep.$invalid && submitted) || v$.fecha_doc_recep.$pending.$response"
              class="p-error">
              {{ v$.fecha_doc_recep.required.$message.replace('Value', 'Fecha') }}</small>
          </div>
        </div>        

        <div class="row">
          <div class="col-md-8 field">
            <label for="proveedor" class="mt-3">Proveedor</label>
            <AutoComplete id="proveedor" v-model="v$.proveedor.$model"
              :class="{ 'p-invalid': v$.proveedor.$invalid && submitted }" :suggestions="filteredProveedores"
              @complete="searchProveedor($event)" field="_razon_social" :dropdown="true" forceSelection
              aria-describedby="proveedor-error" autofocus autocomplete="off" @update:modelValue="buscarCodFscProveedor" @change="setProductos($event, index)"/>
            <span v-if="v$.proveedor.$error && submitted">
              <span id="proveedor-error" v-for="(error, index) of v$.proveedor.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.proveedor.$invalid && submitted) || v$.proveedor.$pending.$response)"
              class="p-error">
              {{ v$.proveedor.required.$message.replace('Value', 'Proveedor') }}</small>
          </div> 
          <div class="col-md-4 field">
            <label for="recepcionista" class="mt-3">Recepcionista</label>
            <AutoComplete id="recepcionista" v-model="v$.recepcionista.$model"
              :class="{ 'p-invalid': v$.recepcionista.$invalid && submitted }" :suggestions="filteredRecepcionistas"
              @complete="searchRecepcionista($event)" field="_nombre" :dropdown="true" forceSelection
              aria-describedby="recepcionista-error" autofocus autocomplete="off" />
            <span v-if="v$.recepcionista.$error && submitted">
              <span id="recepcionista-error" v-for="(error, index) of v$.recepcionista.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.recepcionista.$invalid && submitted) || v$.recepcionista.$pending.$response)"
              class="p-error">
              {{ v$.recepcionista.required.$message.replace('Value', 'Recepcionista') }}</small>
          </div> 
        </div>

        <div class="row">
          <div class="col-md-6 field">
            <label for="origen" class="mt-3">Origen</label>
            <AutoComplete id="origen" v-model="v$.origen.$model"
              :class="{ 'p-invalid': v$.origen.$invalid && submitted }" :suggestions="filteredOrigenes"
              @complete="searchOrigen($event)" field="_descripcion" :dropdown="true" forceSelection
              aria-describedby="origen-error" autofocus autocomplete="off" @change="setProductos($event, index)"/>
            <span v-if="v$.origen.$error && submitted">
              <span id="origen-error" v-for="(error, index) of v$.origen.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.origen.$invalid && submitted) || v$.origen.$pending.$response)"
              class="p-error">
              {{ v$.origen.required.$message.replace('Value', 'Origen') }}</small>
          </div>           
          <div class="col-md-6 field">
            <label for="destino" class="mt-3">Destino</label>
            <AutoComplete id="destino" v-model="v$.destino.$model"
              :class="{ 'p-invalid': v$.destino.$invalid && submitted }" :suggestions="filteredDestinos"
              @complete="searchDestino($event)" field="_descripcion" :dropdown="true" forceSelection
              aria-describedby="destino-error" autofocus autocomplete="off" />
            <span v-if="v$.destino.$error && submitted">
              <span id="destino-error" v-for="(error, index) of v$.destino.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.destino.$invalid && submitted) || v$.destino.$pending.$response)"
              class="p-error">
              {{ v$.destino.required.$message.replace('Value', 'Destino') }}</small>
          </div> 
        </div>

        <div class="row mb-4">
          <div class="col-md-6 field">
            <label for="predio" class="mt-3">Predio</label>
            <InputText id="predio" v-model.trim="form.predio" />
          </div>
          <div class="col-md-6 field">
            <label for="rol" class="mt-3">Rol</label>
            <InputText id="rol" v-model.trim="form.rol" />
          </div>         
        </div>

        <Divider align="left" type="solid">
          <b class="text-muted">Antecedentes Transportista</b>
        </Divider>

        <div class="row">
          <div class="col-md-4 field">
            <label for="transportista" class="mt-3">Transportista</label>
            <AutoComplete id="transportista" v-model="v$.transportista.$model"
              :class="{ 'p-invalid': v$.transportista.$invalid && submitted }" :suggestions="filteredTransportistas"
              @complete="searchTransportista($event)" field="_razon_social" :dropdown="true" forceSelection
              aria-describedby="transportista-error" autofocus autocomplete="off" />
            <span v-if="v$.transportista.$error && submitted">
              <span id="transportista-error" v-for="(error, index) of v$.transportista.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.transportista.$invalid && submitted) || v$.transportista.$pending.$response)"
              class="p-error">
              {{ v$.transportista.required.$message.replace('Value', 'Transportista') }}</small>
          </div> 
          <div class="field" :class="frmchofer.visible ? 'col-md-4' : 'col-md-8'">
            <Button v-if="form.id === null" :label="frmchofer.label" link :class="frmchofer.color" @click="addChofer" style="width: 80px;padding:0px;margin:2px; height:15px;"/>
            <label  v-else for="chofer" class="mt-3">Chofer</label>
            <AutoComplete id="chofer" v-model="form.chofer" @change="dummyClick"
              :suggestions="filteredChoferes"
              @complete="searchChofer($event)" field="_nombre" :dropdown="true" forceSelection
              autofocus :disabled="frmchofer.visible" />
          </div> 
          <div class="col-md-2 field" v-if="frmchofer.visible">
            <label for="chofer" class="mt-3" v-if="frmchofer.visible">RUT Chofer</label>
            <InputText id="rut_dv" v-model="v$.rut_dv.$model" @change="dummyClick"
              :class="{ 'p-invalid': v$.rut_dv.$invalid && submitted }" aria-describedby="rut_dv-error" autofocus
              autocomplete="off" />
            <span v-if="v$.rut_dv.$error && submitted">
              <span id="rut_dv-error" v-for="(error, index) of v$.rut_dv.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.rut_dv.$invalid && submitted) || v$.rut_dv.$pending.$response" class="p-error">
              {{ v$.rut_dv.required.$message.replace('Value', 'RUT') }}</small>
          </div>               
          <div class="col-md-2 field" v-if="frmchofer.visible">
            <label for="chofer" class="mt-3" v-if="frmchofer.visible">Nombre Chofer</label>
            <InputText id="nombre" v-model="form.nombre" autofocus autocomplete="off" @change="dummyClick" />                                     
          </div>                     
        </div>

        <div class="row mb-4">
          <div class="col-md-3 field">
            <label for="patente_camion" class="mt-3">Patente Camion</label>
            <InputText id="patente_camion" v-model="v$.patente_camion.$model" :class="{ 'p-invalid': v$.patente_camion.$invalid && submitted }"
              aria-describedby="patente_camion-error" autocomplete="off" minlength="6" maxlength="6" size="6" @update:modelValue="upperCase($event,'patente_camion')"/>
            <span v-if="v$.patente_camion.$error && submitted">
              <span id="patente_camion-error" v-for="(error, index) of v$.patente_camion.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.patente_camion.$invalid && submitted) || v$.patente_camion.$pending.$response" class="p-error">
              {{ v$.patente_camion.required.$message.replace('Value', 'Valor') }}</small>
          </div>
          <div class="col-md-3 field">
            <label for="patente_carro" class="mt-3">Patente Carro</label>
            <InputText id="patente_carro" v-model="v$.patente_carro.$model" :class="{ 'p-invalid': v$.patente_carro.$invalid && submitted }"
              aria-describedby="patente_carro-error" autocomplete="off" minlength="6" maxlength="6" size="6" @update:modelValue="upperCase($event,'patente_carro')"/>
            <span v-if="v$.patente_carro.$error && submitted">
              <span id="patente_carro-error" v-for="(error, index) of v$.patente_carro.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.patente_carro.$invalid && submitted) || v$.patente_carro.$pending.$response" class="p-error">
              {{ v$.patente_carro.required.$message.replace('Value', 'Valor') }}</small>
          </div>             
          <div class="col-md-3 field">
            <label for="flete_m3" class="mt-3">Valor Flete x M3</label>
            <InputNumber id="flete_m3" v-model="v$.flete_m3.$model" :class="{ 'p-invalid': v$.flete_m3.$invalid && submitted }"
              aria-describedby="flete_m3-error" autofocus autocomplete="off" />
            <span v-if="v$.flete.$error && submitted">
              <span id="flete_m3-error" v-for="(error, index) of v$.flete_m3.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.flete_m3.$invalid && submitted) || v$.flete_m3.$pending.$response" class="p-error">
              {{ v$.flete_m3.required.$message.replace('Value', 'Valor Flete M3') }}</small>
          </div>                
          <div class="col-md-3 field">
            <label for="flete" class="mt-3">Valor Flete</label>
            <InputNumber id="flete" v-model="v$.flete.$model" :class="{ 'p-invalid': v$.flete.$invalid && submitted }"
              aria-describedby="flete-error" autofocus autocomplete="off" />
            <span v-if="v$.flete.$error && submitted">
              <span id="flete-error" v-for="(error, index) of v$.flete.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.flete.$invalid && submitted) || v$.flete.$pending.$response" class="p-error">
              {{ v$.flete.required.$message.replace('Value', 'Valor Flete') }}</small>
          </div>                  
        </div>

        <Divider align="left" type="solid">
          <b class="text-muted">Clasificación FSC</b>
        </Divider>

        <div class="row">
          <div class="col-md-6 field">
            <label for="fsc" class="mt-3">FSC</label>
            <AutoComplete id="fsc" v-model="form.fsc" :suggestions="filteredFscs"
              @complete="searchFsc($event)" field="_nombre" :dropdown="true" forceSelection
              @change="setFscCodigo($event, index)" autofocus/>
          </div>          
          <div class="col-md-6 field">
            <label for="fsc_codigo" class="mt-3">Código FSC</label>
            <InputText id="fsc_codigo" v-model.trim="form.fsc_codigo" />
          </div>                    
        </div>

        <div class="row mt-4">
          <div class="col-md-10 field">
            <Divider align="left" type="solid">
              <b class="text-muted">Detalles</b>
            </Divider>
          </div>
          <div class="col-md-2 d-flex justify-content-end">
            <Button icon="pi pi-plus" class="p-button-rounded p-button" size="small" @click="addLinea()" v-if="(form.origen !== null  && form.proveedor !== null  && form.tipoMadera !== null)" />
          </div>
          <div class="col-md-12 field">
            <InlineMessage v-for="msg of messages" :key="msg.id" severity="warn">{{ msg.content }}</InlineMessage>
          </div>
        </div>
        <!-- DETALLES -->
        <div class="card p-fluid table_modal">
          <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px" editMode="cell" @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table" tableStyle="min-width: 50rem">
            <Column style="flex: 0 0 4rem">
              <template #body="{ index }" >
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" size="small" @click="toggleDelete(index)"/>
              </template>
            </Column> 
            <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :hidden="col.hidden" :style="col.field !== 'producto' ? 'max-width: 8%;text-align:right' :'max-width: 10%'">
              <template #editor="{ data, field }">
                <template v-if="field !== 'producto'">
                  <InputNumber else v-model="data[field]" autofocus :useGrouping="false"
                  :maxFractionDigits="/alto|largo|mc_|total/.test(field) ? 3 : (/espesor|pulg_|mruma_|diametro|cant_/.test(field) ? 0 : 2)" 
                  :readonly="/9|10|11/.test(form.tipoMadera.id) ? /mc_|total|total_mask/.test(field) :/total|total_mask/.test(field)"
                  />
                </template>
                <template v-else>
                  <AutoComplete v-model="data[field]" optionLabel="_nombre"  :optionValue="data[field]" :suggestions="filteredProductos" @complete="searchProducto($event)"/>
                </template>
              </template>
            </Column>
            <ColumnGroup type="footer" v-if="csr2 > 0">
              <Row>
                <Column footer="" :colspan="csr1+2" footerStyle="text-align:right;background-color: #efefef" v-if="csr2 > 4"/>
                <Column :footer="`${parseInt(totalCantDespacho)}`" footerStyle="text-align:right;background-color: #efefef" v-if="csr1 > 3 || form.tipoMadera.id === 9"/>
                <Column :footer="`${parseFloat(totalM3Despacho).toFixed(3)}`" footerStyle="text-align:right;background-color: #efefef" v-if="csr2 > 4 && form.tipoMadera.id !== 11"/>
                <Column :footer="`${parseFloat(totalMRDespacho).toFixed(3)}`" footerStyle="text-align:right;background-color: #efefef" v-if="csr2 > 4 && form.tipoMadera.id === 11"/>                               
                <Column :footer="`${parseInt(totalCantRecepcion)}`" footerStyle="text-align:right;background-color: #efefef" v-if="csr1 > 3 || form.tipoMadera.id === 9"/>
                <Column :footer="`${parseFloat(totalM3Recepcion).toFixed(3)}`" footerStyle="text-align:right;background-color: #efefef" v-if="csr2 > 4 && form.tipoMadera.id !== 11"/>
                <Column :footer="`${parseFloat(totalMRRecepcion).toFixed(3)}`" footerStyle="text-align:right;background-color: #efefef" v-if="csr2 > 4 && form.tipoMadera.id === 11"/>
                <Column footer="Neto" footerStyle="text-align:right;background-color: #efefef"/>
                <Column :footer="netoRecepcion" footerStyle="text-align:right;background-color: #efefef"/>
              </Row>
              <Row>
                <Column :footer="`IVA (${form.poriva})%`" :colspan="csr2" footerStyle="text-align:right;background-color: #efefef"/>
                <Column :footer="ivaRecepcion" footerStyfieldformle="text-align:right;background-color: #efefef"/>
              </Row>
              <Row>
                <Column footer="Total" :colspan="csr2" footerStyle="text-align:right;background-color: #efefef"/>
                <Column :footer="totalRecepcion" footerStyle="text-align:right;background-color: #efefef"/>
              </Row>
            </ColumnGroup>                       
          </DataTable>

        </div>

        <div class="row">
          <div class="col-md-12 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <Textarea autoResize id="observacion" v-model.trim="form.observacion" />
          </div>                   
        </div>
      </form>

      <template #footer>
        <div class="row">
          <div class="col-md-2 fw-lighter text-muted field mt-2" style="text-align: left;">
          Tipo Registro: &nbsp;<Tag :value="form.tipo_registro" severity="info" />
          </div>
          <div class="col-md-5 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-5 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveRecepcion"/>
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-5 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
        <span id="dummy"></span>
      </template>
    </Dialog>

    <!-- Confirmar elimina un recepcion -->
    <Dialog v-model:visible="deleteRecepcionDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="recepcion"> ¿Está seguro de borrar el recepcion?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteRecepcionDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteRecepcion" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente recepciones -->
    <Dialog v-model:visible="deleteRecepcionesDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedRecepciones"> ¿Está seguro de borrar los recepciones seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteRecepcionesDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedRecepciones" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useRecepciones from '../../composables/recepciones/index'
import { reactive, onMounted, computed, isProxy, toRaw} from 'vue'
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import useInformes from '../../composables/informes/index'

export default {
  data() {
		return {
			userRole: window.Laravel.user.seg_rol_id,
		}
	},
  setup() {
    const {
      porciva,
      nextid,
      recepcion,
      chofer,
      tipoMadera,
      fsc,
      recepciones,
      tipoDocReceps,
      proveedores,
      recepcionistas,
      choferes,
      tipoMaderas,
      fscs,
      destinos,
      origenes,
      transportistas,
      productos,
      columns,
      dt, 
      loading, 
      saving,
      filters,
      selectedRecepciones,
      deleteRecepcionesDialog,
      deleteRecepcionDialog,
      recepcionDialog,
      submitted,
      filteredTipoDocReceps,
      filteredProveedores,
      filteredRecepcionistas,
      filteredFscs,
      filteredChoferes,
      filteredTipoMaderas,
      filteredDestinos,
      filteredOrigenes,
      filteredTransportistas,
      filteredProductos,
      selectedProductos,
      getProdsRecep,
      lineas,
      messages,
      storeRecepcion,      
      deleteSelectedRecepciones,
      deleteRecepcion,
      getCodFsc,
      csr1,
      csr2,
      totalRecords,
      first,
      lazyParams,
      loadLazyData,
      onPage,
      onSort,
      onFilter,
      getPDFSII,
      initFilters
    } = useRecepciones()

    const {
      recepcionPDF,
      informeData
    } = useInformes()

    const oldTMadera = reactive({})

    const frmchofer = reactive({
      'label' : '+ Chofer',
      'visible' : false,
      'color' : 'text-success mt-4'
    })

    const form = reactive({
      id: null,
      nextid: null,
      tipoDocRecep: '',
      proveedor: null,
      origen: null,      
      destino: '',
      transportista: '',
      chofer: '',
      tipoMadera: '',
      recepcionista: '',
      fsc: null,
      fsc_codigo: '',
      folio_doc_recep: null,
      fecha_doc_recep: null,
      fecha: moment().toDate(),
      patente_camion: '',
      patente_carro: '',
      flete: 0,
      flete_m3: 0,
      poriva: porciva.value,
      neto: null,
      iva: null,
      total: null,
      predio: '',
      rol: '',
      observacion: '',
      tipo_registro: '',
      rut_dv: '',
      nombre: '',      
      detalles: []
    })

    const editRecepcion = (rec) => {
      lineas.value = []
      recepcion.value = { ...rec }
      recepcionDialog.value = true
      form.value = {}    
      csr1.value = 0
      csr2.value = 0

      let detalles = isProxy(recepcion.value.productos) ? toRaw(recepcion.value.productos) : []
      let total 
      let total_mask
      lineas.value = detalles.reduce((acc, ln) => {
        total = 0
        total_mask  = 0
        //baza :  (espesor X ancho X largo X cantidad)/32
        let pulg_despacho = ln.tipo_madera_id === 7 ? parseFloat(((ln.pivot.espesor * ln.pivot.ancho * ln.pivot.largo * ln.pivot.cant_despacho)/32)).toFixed(3) : 0
        let pulg_recepcion = ln.tipo_madera_id === 7 ? parseFloat(((ln.pivot.espesor * ln.pivot.ancho * ln.pivot.largo * ln.pivot.cant_despacho))/32).toFixed(3) : 0

        //trozo
        let m3_desp = ln.tipo_madera_id === 9 ? (Math.round(((parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.largo).toFixed(2)).toFixed(3)/10000)*1000)/1000) * ln.pivot.cant_despacho : 0
        let m3_recep = ln.tipo_madera_id === 9 ? (Math.round(((parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.largo).toFixed(2)).toFixed(3)/10000)*1000)/1000) * ln.pivot.cant_recepcion : 0
 
        //lampazo
        m3_desp = ln.tipo_madera_id === 10 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)).toFixed(3) : 0
        m3_recep = ln.tipo_madera_id === 10 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)).toFixed(3) : 0

        //mruma
        let mruma_desp = ln.tipo_madera_id === 11 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)/2.44).toFixed(3) : 0
        let mruma_recep = ln.tipo_madera_id === 11 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)/2.44).toFixed(3) : 0

        switch (ln.tipo_madera_id) {
          case 7:
            total = ln.pivot.total
            total_mask = ln.pivot.total
            break;
          case 9:
            total = m3_recep > 0 ? parseFloat(Math.round(parseFloat(m3_recep).toFixed(3) * ln.pivot.precio *1000)/1000).toFixed(0) : parseFloat(Math.round(parseFloat(ln.pivot.mc_recepcion).toFixed(3) * ln.pivot.precio *1000)/1000).toFixed(0)
            total_mask = total
            break;
          case 10:
            total = ln.pivot.total
            total_mask = ln.pivot.total
            break;
          case 11:
            total = mruma_recep > 0 ? parseFloat(Math.round(parseFloat(mruma_recep).toFixed(3) * ln.pivot.precio)).toFixed(0) : parseFloat(Math.round(parseFloat(ln.pivot.mruma_recep).toFixed(3) * ln.pivot.precio)).toFixed(0)
            total_mask = total
            break;      
          default:
            total = ln.pivot.total
            total_mask = ln.pivot.total
        }

        acc.push({
          'producto_obj' : {'id': ln.id, 'nombre': ln.nombre},
          'producto' : ln.id+'-'+ln.nombre,
          'diametro' : ln.pivot.diametro,
          'espesor' : ln.pivot.espesor,
          'largo' : ln.pivot.largo,
          'alto' : ln.pivot.alto,
          'ancho' : ln.pivot.ancho,
          'cant_despacho' : ln.pivot.cant_despacho,
          'pulg_despacho' : pulg_despacho > 0 ? pulg_despacho : ln.pivot.pulg_despacho,
          'mc_despacho' :  m3_desp > 0 ? parseFloat(m3_desp).toFixed(3) :  parseFloat(ln.pivot.mc_despacho).toFixed(3),
          'cant_recepcion' : ln.pivot.cant_recepcion,
          'pulg_recepcion' : pulg_recepcion > 0 ? pulg_recepcion : ln.pivot.pulg_recepcion,
          'mruma_recepcion' : mruma_recep ? mruma_recep : ln.pivot.mruma_recepcion,
          'mruma_despacho' : mruma_desp ? mruma_desp : ln.pivot.mruma_despacho,
          'mc_recepcion' :  m3_recep > 0 ? parseFloat(m3_recep).toFixed(3) : parseFloat(ln.pivot.mc_recepcion).toFixed(3),
          'precio' : ln.pivot.precio,
          'total' : total,
          'total_mask': total_mask
        });
        return acc;
      }, [])         
           
      form.detalles = lineas.value
      form.id = rec.id
      form.nextid = rec.id
      form.tipoDocRecep = recepcion.value.tipoDocRecep
      form.proveedor = recepcion.value.proveedor
      form.origen = recepcion.value.origen
      form.destino = recepcion.value.destino
      form.transportista = recepcion.value.transportista
      form.chofer = recepcion.value.chofer
      form.tipoMadera = recepcion.value.tipoMadera
      form.recepcionista = recepcion.value.recepcionista
      form.fsc = recepcion.value.fsc
      form.fsc_codigo = recepcion.value.fsc_codigo
      form.folio_doc_recep = recepcion.value.folio_doc_recep
      form.fecha_doc_recep = recepcion.value.fecha_doc_recep
      form.fecha = recepcion.value.fecha
      form.patente_camion = recepcion.value.patente_camion
      form.patente_carro = recepcion.value.patente_carro
      form.flete = recepcion.value.flete
      form.flete_m3 = 0
      form.poriva = recepcion.value.poriva === 0 ? porciva.value : recepcion.value.poriva
      form.neto = recepcion.value.neto
      form.iva = recepcion.value.iva
      form.total = recepcion.value.total
      form.predio = recepcion.value.predio      
      form.rol = recepcion.value.rol      
      form.observacion = recepcion.value.observacion
      form.tipo_registro = recepcion.value.tipo_registro ? recepcion.value.tipo_registro.toUpperCase() : ''

      form.created_at = recepcion.value.created_at
      form.created_id = recepcion.value.created_id
      form.updated_at = recepcion.value.updated_at
      form.updated_id = recepcion.value.updated_id
      
      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false 

      oldTMadera.value = form.tipoMadera
      configColumns()
    }

    const duplicarGuia = (rec) => {+6+7/5
      lineas.value = []
      recepcion.value = { ...rec }
      recepcionDialog.value = true
      form.value = {}    
      csr1.value = 0
      csr2.value = 0

      let detalles = isProxy(recepcion.value.productos) ? toRaw(recepcion.value.productos) : []

      lineas.value = detalles.reduce((acc, ln) => {
        //baza
        let pulg_despacho = ln.tipo_madera_id === 7 ? parseFloat(((ln.pivot.espesor * ln.pivot.ancho)/10) * ln.pivot.cant_despacho).toFixed(3) : 0
        let pulg_recepcion = ln.tipo_madera_id === 7 ? parseFloat(((ln.pivot.espesor * ln.pivot.ancho)/10) * ln.pivot.cant_recepcion).toFixed(3) : 0
        //trozo
        let m3_desp = ln.tipo_madera_id === 9 ? (Math.round(((parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.largo).toFixed(2)).toFixed(3)/10000)*1000)/1000) * ln.pivot.cant_despacho : 0
        let m3_recep = ln.tipo_madera_id === 9 ? (Math.round(((parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.diametro).toFixed(0) * parseFloat(ln.pivot.largo).toFixed(2)).toFixed(3)/10000)*1000)/1000)* ln.pivot.cant_recepcion : 0
        //lampazo
        m3_desp = ln.tipo_madera_id === 10 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)).toFixed(3) : 0
        m3_recep = ln.tipo_madera_id === 10 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)).toFixed(3) : 0
        //mruma
        let mruma_desp = ln.tipo_madera_id === 11 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)/2.44).toFixed(3) : 0
        let mruma_recep = ln.tipo_madera_id === 11 ? parseFloat((ln.pivot.alto * ln.pivot.ancho * ln.pivot.largo)/2.44).toFixed(3) : 0

        acc.push({
          'producto_obj' : {'id': ln.id, 'nombre': ln.nombre, 'metro3': ln.metro3, 'mm': ln.mm, 'mruma': ln.mruma, 'nulo': ln.nulo, 'pulgada': ln.pulgada,'unidad': ln.unidad  },
          'producto' : ln.id+'-'+ln.nombre,
          'diametro' : ln.pivot.diametro,
          'espesor' : ln.pivot.espesor,
          'largo' : ln.pivot.largo,
          'alto' : ln.pivot.alto,
          'ancho' : ln.pivot.ancho,
          'cant_despacho' : ln.pivot.cant_despacho,
          'pulg_despacho' : pulg_despacho > 0 ? pulg_despacho : ln.pivot.pulg_despacho,
          'mc_despacho' :  m3_desp > 0 ? m3_desp :  parseFloat(ln.pivot.mc_despacho).toFixed(3),
          'cant_recepcion' : ln.pivot.cant_recepcion,
          'pulg_recepcion' : pulg_recepcion > 0 ? pulg_recepcion : ln.pivot.pulg_recepcion,
          'mruma_recepcion' : mruma_recep ? mruma_recep : ln.pivot.mruma_recepcion,
          'mruma_despacho' : mruma_desp ? mruma_desp : ln.pivot.mruma_despacho,
          'mc_recepcion' :  m3_recep > 0 ? m3_recep : parseFloat(ln.pivot.mc_recepcion).toFixed(3),
          'precio' : ln.pivot.precio,
          'total' : ln.pivot.total,     
          'total_mask' : ln.pivot.total     
        });
        return acc;
      }, [])

      form.detalles = lineas.value
      form.id = rec.id
      form.nextid = rec.id
      form.tipoDocRecep = recepcion.value.tipoDocRecep ? toRaw(tipoDocReceps.value.filter(function (itm) { return [recepcion.value.tipoDocRecep.id].indexOf(itm.id) > -1 }))[0] : null
      form.proveedor = recepcion.value.proveedor ? toRaw(proveedores.value.filter(function (itm) { return [recepcion.value.proveedor.id].indexOf(itm.id) > -1 }))[0] : null
      form.origen = recepcion.value.origen ? toRaw(origenes.value.filter(function (itm) { return [recepcion.value.origen.id].indexOf(itm.id) > -1 }))[0] : null
      form.destino = recepcion.value.destino ? toRaw(origenes.value.filter(function (itm) { return [recepcion.value.destino.id].indexOf(itm.id) > -1 }))[0] : null
      form.transportista = recepcion.value.transportista
      form.chofer = recepcion.value.chofer ? toRaw(choferes.value.filter(function (itm) { return [recepcion.value.chofer.id].indexOf(itm.id) > -1 }))[0] : null
      form.tipoMadera = recepcion.value.tipoMadera ? toRaw(tipoMaderas.value.filter(function (itm) { return [recepcion.value.tipoMadera.id].indexOf(itm.id) > -1 }))[0] : null
      form.recepcionista = recepcion.value.recepcionista ? toRaw(recepcionistas.value.filter(function (itm) { return [recepcion.value.recepcionista.id].indexOf(itm.id) > -1 }))[0] : null
      form.fsc = recepcion.value.fsc !== null ? toRaw(fscs.value.filter(function (itm) { return [recepcion.value.fsc.id].indexOf(itm.id) > -1 }))[0] : null
      form.fsc_codigo = recepcion.value.fsc_codigo
      form.fecha = recepcion.value.fecha
      form.patente_camion = recepcion.value.patente_camion
      form.patente_carro = recepcion.value.patente_carro
      form.flete = recepcion.value.flete
      form.flete_m3 = 0
      form.poriva = recepcion.value.poriva === 0 ? porciva.value : recepcion.value.poriva
      form.neto = recepcion.value.neto
      form.iva = recepcion.value.iva
      form.total = recepcion.value.total
      form.predio = recepcion.value.predio      
      form.rol = recepcion.value.rol      
      form.observacion = recepcion.value.observacion

      form.created_at = recepcion.value.created_at
      form.created_id = recepcion.value.created_id
      form.updated_at = recepcion.value.updated_at
      form.updated_id = recepcion.value.updated_id
      
      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false 

      oldTMadera.value = form.tipoMadera
      configColumns()

      //nullable para que duplique la guia y lo identifique como un nuevo registro 
      form.id = null
      form.nextid = nextid.value      
      form.folio_doc_recep = null
      form.fecha_doc_recep = null
      form.fecha = moment().toDate()      
    }

    const openNew = () => {
      recepcion.value = {}
      submitted.value = false
      recepcionDialog.value = true
      saving.value = false
      lineas.value = []
      csr1.value = 0
      csr2.value = 0 

      form.id = null
      form.nextid = nextid.value
      form.tipoDocRecep = ''
      form.proveedor = null
      form.origen = null     
      form.destino = ''
      form.transportista = ''
      form.chofer = ''
      form.tipoMadera = null
      form.recepcionista = ''
      form.fsc = null
      form.fsc_codigo = ''
      form.folio_doc_recep = null
      form.fecha_doc_recep = null
      form.fecha = moment().toDate()
      form.patente_camion = ''
      form.patente_carro = ''
      form.flete = 0,
      form.flete_m3 = 0,
      form.poriva = porciva.value
      form.neto = null
      form.iva = null
      form.total = null
      form.predio = ''
      form.rol = ''
      form.observacion = ''
      form.tipo_registro = ''
      form.detalles = []
      
      oldTMadera.value = {}

      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false       
    }

    const rut_format = helpers.regex(/^(\d{1,3}(?:\d{1,3}){2}-[\dkK])$/)
    const greaterThanZero = (value) => value > 0

    const rules = {
      tipoDocRecep: { required: helpers.withMessage('El campo es requerido o no corresponde a la recepcion', required), },
      proveedor: { required: helpers.withMessage('El campo es requerido!', required), },
      origen: { required: helpers.withMessage('El campo es requerido!', required), },            
      destino: { required: helpers.withMessage('El campo es requerido!', required), },  
      transportista: { required: helpers.withMessage('El campo es requerido!', required), },                 
      tipoMadera: { required: helpers.withMessage('El campo es requerido!', required), },                
      recepcionista: { required: helpers.withMessage('El campo es requerido!', required), },
      folio_doc_recep: { required: helpers.withMessage('El campo es requerido', required), },
      fecha_doc_recep: { required: helpers.withMessage('El campo es requerido', required), }, 
      fecha: { required: helpers.withMessage('El campo es requerido', required), }, 
      patente_camion: { required: helpers.withMessage('El campo es requerido', required), },
      patente_carro: { required: helpers.withMessage('El campo es requerido', required), },           
      flete: { required: helpers.withMessage('El campo es requerido', required), },
      flete_m3: { required: helpers.withMessage('El campo es requerido', required), },
      neto: { required: helpers.withMessage('El campo es requerido', required), },
      iva: { required: helpers.withMessage('El campo es requerido', required), 
             minValue: helpers.withMessage('El iva debe ser mayor a cero', greaterThanZero), },
      total: { required: helpers.withMessage('El campo es requerido', required), },
      rut_dv: {
        rut_rule: helpers.withMessage('Ingrese un rut válido. Ej: 12345678-0', rut_format),
      }, 
    }

    const addChofer = () => {
      form.rut_dv = null
      form.nombre = null        
      frmchofer.label = frmchofer.visible ? '+ Chofer' : '- Chofer'
      frmchofer.color = frmchofer.visible ? 'text-success mt-4' : 'text-danger mt-4'
      frmchofer.visible = !frmchofer.visible     
    }

    const confirmDeleteSelected = () => {
      deleteRecepcionesDialog.value = true
    }

    const confirmDeleteRecepcion = (emp) => {
      recepcion.value = emp
      deleteRecepcionDialog.value = true
    }

    const hideDialog = () => {
      recepcionDialog.value = false
      recepcion.value = {}
      submitted.value = false
      saving.value = false
      
      lineas.value = []
      csr1.value = 0
      csr2.value = 0 

      form.id = null
      form.nextid = nextid.value
      form.tipoDocRecep = ''
      form.proveedor = null
      form.origen = null     
      form.destino = ''
      form.transportista = ''
      form.chofer = ''
      form.tipoMadera = null
      form.recepcionista = ''
      form.fsc = null
      form.fsc_codigo = ''
      form.folio_doc_recep = null
      form.fecha_doc_recep = null
      form.fecha = moment().toDate()
      form.patente_camion = ''
      form.patente_carro = ''
      form.flete = 0,
      form.flete_m3 = 0,
      form.poriva = porciva.value
      form.neto = null
      form.iva = null
      form.total = null
      form.predio = ''
      form.rol = ''
      form.observacion = ''
      form.tipo_registro = ''
      form.detalles = []
      
      oldTMadera.value = {}

      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false           
    }

    const searchTipoDocRecep = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoDocReceps.value = [...tipoDocReceps.value]
        }
        else {
          filteredTipoDocReceps.value = tipoDocReceps.value.filter((tdocumento) => {
            return tdocumento._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchProveedor = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProveedores.value = [...proveedores.value]
        }
        else {
          filteredProveedores.value = proveedores.value.filter((proveedor) => {
            return proveedor._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchRecepcionista = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredRecepcionistas.value = [...recepcionistas.value]
        }
        else {
          filteredRecepcionistas.value = recepcionistas.value.filter((recepcionista) => {
            return recepcionista._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchFsc = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredFscs.value = [...fscs.value]
        }
        else {
          filteredFscs.value = fscs.value.filter((fsc) => {
            return fsc._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchChofer = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredChoferes.value = [...choferes.value]
        }
        else {
          filteredChoferes.value = choferes.value.filter((chofer) => {
            return chofer._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchTipoMadera = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoMaderas.value = [...tipoMaderas.value]
        }
        else {
          filteredTipoMaderas.value = tipoMaderas.value.filter((tmadera) => {
            return (tmadera._nombre).toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchDestino = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredDestinos.value = [...destinos.value]
        }
        else {
          filteredDestinos.value = destinos.value.filter((destino) => {
            return destino._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchOrigen = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredOrigenes.value = [...origenes.value]
        }
        else {
          filteredOrigenes.value = origenes.value.filter((origen) => {
            return origen._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchTransportista = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTransportistas.value = [...transportistas.value]
        }
        else {
          filteredTransportistas.value = transportistas.value.filter((transportista) => {
            return transportista._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchProducto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProductos.value = [...selectedProductos.value]
        }
        else {
          filteredProductos.value = selectedProductos.value.filter((producto) => {
            return producto._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const onCellEditComplete = (event) => {
      let { data, newValue, field } = event

      let { m3_desp, m3_recep, precio } = 0
      data[field] = newValue
      messages.value = []
      data['largo'] = parseFloat(data.largo).toFixed(3)
      data['alto'] = parseFloat(data.alto).toFixed(3)
      data['ancho'] = parseFloat(data.ancho).toFixed(2)
      data['espesor'] = parseFloat(data.espesor).toFixed(2)
      
      if(!field.includes('producto')){
        //aqui los calculos segun el tipo de madera seleccionado
        if(form.tipoMadera.id === 7){//baza
          data['espesor'] = parseFloat(data['espesor']).toFixed(2)
          data['ancho'] = parseFloat(data['ancho']).toFixed(2)
          data['largo'] = parseFloat(data['largo']).toFixed(3)
          data['cant_despacho'] = parseInt(data['cant_despacho'])
          data['pulg_despacho'] = parseFloat(((data['espesor'] * data['ancho'] * data['largo'] * data['cant_despacho'])/32)).toFixed(3)
          data['mc_despacho'] = parseFloat(((data['espesor'] * data['ancho'] * data['largo'] * data['cant_despacho'])/32)/48.432).toFixed(2)
          data['cant_recepcion'] = parseInt(data['cant_recepcion'])
          data['pulg_recepcion'] = parseFloat(((data['espesor'] * data['ancho'] * data['largo']* data['cant_recepcion'])/32)).toFixed(3)
          data['mc_recepcion'] = parseFloat(((data['espesor'] * data['ancho'] * data['largo']* data['cant_recepcion'])/32)/48.432).toFixed(2)
          data['precio'] = parseInt(data['precio'])
          data['total'] = parseInt(data['precio'] * data['cant_recepcion']).toFixed(0)
          data['total_mask'] = data['total'].toLocaleString('es-CL')
        }else if(form.tipoMadera.id === 9 ){ //trozo
        //aqui lo del precio        
          if(field === 'diametro'){
            if(data['diametro'] > 13) {
              productos.value.filter(function (itm) { 
                if([data['producto_obj'].id].indexOf(itm.id) > -1 ){
                  precio = itm.precios !== null ? itm.precios.filter((pd) => { return parseInt(pd.diametro) === parseInt(data['diametro']) ? parseInt(pd.valor_m3) : 0 }) : []
                }else return 0
              })
              data['precio'] = precio.length > 0 ? parseInt(precio[0].valor_m3) : 0
            }else data['precio'] = 0
          }         
          
          if((data.diametro)%2==0 && data.diametro > 13){            
            m3_desp = Math.round(((parseFloat(data['diametro']).toFixed(0) * parseFloat(data['diametro']).toFixed(0) * parseFloat(data['largo']).toFixed(2)).toFixed(3)/10000)*1000)/1000
            m3_recep = Math.round(((parseFloat(data['diametro']).toFixed(0) * parseFloat(data['diametro']).toFixed(0) * parseFloat(data['largo']).toFixed(2)).toFixed(3)/10000)*1000)/1000

            data['cant_despacho'] = parseFloat(data.cant_despacho).toFixed(0)
            data['cant_recepcion'] = field === 'cant_recepcion' && newValue !== data.cant_despacho ? parseFloat(data.cant_recepcion).toFixed(0) : parseFloat(data.cant_despacho).toFixed(0) 
            data['mc_despacho'] = parseFloat(m3_desp * data['cant_despacho']).toFixed(3) 
            data['mc_recepcion'] = parseFloat(m3_recep * data['cant_recepcion']).toFixed(3) 
            // console.info('m3 >>>>>>', m3_desp, m3_desp)
            data['total'] = (data['precio'] * data['mc_recepcion']).toFixed(0)
            data['total_mask'] = data['total'].toLocaleString('es-CL')
          }else { 
            messages.value = [{ content: 'Ingrese un diámetro válido (valor par) ', id: 0 }]
            data['diametro'] = 0
            return false
          }        
        }else if(form.tipoMadera.id === 10){ //lampazo
          data['alto'] = parseFloat(data['alto']).toFixed(2)
          data['ancho'] = parseFloat(data['ancho']).toFixed(2)
          data['largo'] = parseFloat(data['largo']).toFixed(3)
          data['cant_despacho'] = 1
          data['mc_despacho'] = parseFloat((data['alto'] * data['ancho'] * data['largo'])).toFixed(3)
          data['cant_recepcion'] = 1
          data['mc_recepcion'] = parseFloat((data['alto'] * data['ancho'] * data['largo'])).toFixed(3)
          data['precio'] = parseInt(data['precio'])
          data['total'] = parseInt(data['precio'] * data['cant_recepcion'] * data['mc_recepcion']).toFixed(0)
          data['total_mask'] = data['total'].toLocaleString('es-CL')
        }else if(form.tipoMadera.id === 11){ //mruma
          data['alto'] = parseFloat(data['alto']).toFixed(2)
          data['ancho'] = parseFloat(data['ancho']).toFixed(2)
          data['largo'] = parseFloat(data['largo']).toFixed(3)
          data['cant_despacho'] = 1
          data['mruma_despacho'] = parseFloat((data['alto'] * data['ancho'] * data['largo'])/2.44).toFixed(3)
          data['cant_recepcion'] = 1
          data['mruma_recepcion'] = parseFloat((data['alto'] * data['ancho'] * data['largo'])/2.44).toFixed(3)
          data['precio'] = parseInt(data['precio'])
          data['total'] = parseInt(data['precio'] * data['cant_recepcion'] * data['mruma_recepcion']).toFixed(0)
          data['total_mask'] = data['total'].toLocaleString('es-CL')
        }else{ //otros
          data['mc_despacho'] = parseFloat(data['mc_despacho']).toFixed(3)
          data['mc_recepcion'] = parseFloat(data['mc_recepcion']).toFixed(3)
          // data['cant_despacho'] = 1
          // data['cant_recepcion'] = 1
          data['precio'] = parseInt(data.precio)
          data['total'] = parseInt(data['mc_recepcion']) > 0 ? parseInt(data['precio'] * data['mc_recepcion']).toFixed(0) : parseInt(data['precio'] * data['cant_recepcion']).toFixed(0)
          data['total_mask'] = data['total'].toLocaleString('es-CL')          
        }
      }else{ 
        data['largo'] = parseFloat(data.largo).toFixed(3)
        data['alto'] = parseFloat(data.alto).toFixed(3)
        data['ancho'] = parseFloat(data.ancho).toFixed(2)
        data['espesor'] = parseFloat(data.espesor).toFixed(2)
        if(newValue.nombre !== undefined)
          if (newValue.nombre.trim().length > 0) {
            data[field] = newValue.id +'-'+ newValue.nombre
            data['producto_obj'] = {'id': newValue.id, 'nombre': newValue.nombre},
            messages.value = []
            configColumns()
          }else event.preventDefault()   
      }
    }

    const configColumns = () => {
      columns.value.map((col) => {
        if(form.tipoMadera.id === 7){
          col.hidden = /total_mask|producto_obj|diametro|alto|mruma_/.test(col.field) ? true : false
          csr1.value = 5
          csr2.value = 12
        }else if(form.tipoMadera.id === 9 ){
          col.hidden = /total_mask|producto_obj|espesor|ancho|alto|pulg_|mruma_/.test(col.field) ? true : false    
          csr1.value = 2         
          csr2.value = 9          
        }else if(form.tipoMadera.id === 10 ){
          col.hidden = /total_mask|producto_obj|diametro|espesor|cant_|mruma_|pulg_/.test(col.field) ? true : false 
          csr1.value = 3
          csr2.value = 8
        }else if(form.tipoMadera.id === 11){
          col.hidden = /total_mask|producto_obj|diametro|espesor|cant_|mc_|pulg_/.test(col.field) ? true : false 
          csr1.value = 3
          csr2.value = 8
        }else{ 
          col.hidden = /total_mask|producto_obj|diametro|espesor|ancho|alto|largo|pulg_|mruma_/.test(col.field) ? true : false 
          csr1.value = 2
          csr2.value = 7
        }                           
      })
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value)
    }

    const toggleDelete= (index) => {
      lineas.value.splice(index, 1)
      messages.value = []
    }

    const upperCase = ($event, field) => {
      form[field] = $event.toUpperCase()
    }    

    const saveRecepcion = () => {
      dummyClick()
      submitted.value = true
      //validar si esta creando un chofer
      messages.value = []
      if(form.chofer !== null || (form.rut_dv !== null && form.nombre !== null))
        if(!v$._value.$invalid){
          if(lineas.value.length > 0){
            messages.value = []
            form.detalles = lineas
            storeRecepcion(form)  
          }else
            messages.value = [{ content: 'Debe seleccionar el producto antes de guardar la recepción', id: lineas.value.length }]
        }else
          messages.value = [{ content: 'El IVA deber ser mayor a Cero (0)', id: 0 }]
      else
        messages.value = [{ content: 'Debe seleccionar un chofer o indicar los datos de uno nuevo', id: lineas.value.length }]
    }

    const addLinea = () => {
      let row = {
        'producto_obj' : '',
        'producto' : '',
        'diametro' : 0,
        'espesor' : 0,
        'alto' : 0,
        'largo' : 0,
        'ancho' : 0,
        'cant_despacho' : 0,
        'mc_despacho' : 0,
        'mruma_despacho' : 0,
        'pulg_despacho' : 0,
        'cant_recepcion' : 0,
        'mc_recepcion' : 0,
        'mruma_recepcion' : 0,
        'pulg_recepcion' : 0,
        'precio' : 0,
        'total' : 0,
        'total_mask' : 0
      }
      
      if(lineas.value.length === 0)
        lineas.value.unshift(row)
      else
        if(lineas.value[lineas.value.length - 1].producto_obj !== '')
          lineas.value.unshift(row)
        else
          messages.value = [{ content: 'Debe seleccionar el producto antes de agregar nueva línea', id: lineas.value.length }]
      setProductos()
    }

    const totalCantDespacho = computed(() => {
      let cantDespacho = 0;
      for(let ln of lineas.value) {
        cantDespacho += Number(ln.cant_despacho)
      }
      form.cant_despacho = cantDespacho
      return cantDespacho
    })

    const totalM3Despacho = computed(() => {
      let m3Despacho = 0;
      for(let ln of lineas.value) {
        m3Despacho += Math.round(ln.mc_despacho * 1000 ) / 1000
      }
      form.mc_despacho = m3Despacho
      return m3Despacho
    })

    const totalMRDespacho = computed(() => {
      let mrDespacho = 0;
      for(let ln of lineas.value) {
        mrDespacho += Math.round(ln.mruma_despacho * 1000 ) / 1000
      }
      form.mruma_despacho = mrDespacho
      return mrDespacho
    })

    const totalCantRecepcion = computed(() => {
      let cantRecepcion = 0;
      for(let ln of lineas.value) {
        cantRecepcion += Number(ln.cant_recepcion)
      }
      form.cant_despacho = cantRecepcion
      return cantRecepcion
    })

    const totalM3Recepcion = computed(() => {
      let m3Recepcion = 0;
      for(let ln of lineas.value) {
        m3Recepcion += Math.round(ln.mc_recepcion * 1000 ) / 1000
      }
      form.mc_recepcion = m3Recepcion
      return m3Recepcion
    })

    const totalMRRecepcion = computed(() => {
      let mrRecepcion = 0;
      for(let ln of lineas.value) {
        mrRecepcion += Math.round(ln.mruma_recepcion * 1000 ) / 1000
      }
      form.mruma_recepcion = mrRecepcion
      return mrRecepcion
    })    

    const netoRecepcion = computed(() => {
      let neto = 0;
      for(let ln of lineas.value) {
        neto += Number(ln.total)
      }
      form.neto = neto
      return formatCurrency(neto)
    })

    const ivaRecepcion = computed(() => {
      let iva = 0;
      iva = form.neto * form.poriva / 100
      form.iva = iva
      return formatCurrency(iva)
    })

    const totalRecepcion = computed(() => {
      let total = 0;
      total = form.neto + form.iva
      form.total = form.neto + form.iva
      return formatCurrency(total)
    })

    const setProductos = () => {
      // si cambia el tipo de madera deben refrescarse los detalles de la recepcion
      if(form.tipoMadera){
        if(form.tipoMadera !== oldTMadera.value){
          oldTMadera.value = form.tipoMadera
          lineas.value = []
          messages.value = []
          configColumns()
        }
      }

      if(form.origen !== null  && form.proveedor !== null  && form.tipoMadera !== null) {
        selectedProductos.value = []
        getProdsRecep(form.origen.id, form.proveedor.id, form.tipoMadera.id)
      }
    }

    const buscarCodFscProveedor = () => {
      let proveedor = isProxy(form.proveedor) ? toRaw(form.proveedor) : null
      if(proveedor) getCodFsc(proveedor.id)
    }

    const setFscCodigo = (event) => {  
      form.fsc_codigo = event.value.codigo
    }
    
    const dummyClick = () => {
      document.getElementById('dummy').click()
    }

    onMounted(() => {
      loading.value = true
      lazyParams.value = {
        first: dt.value.first,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }
      loadLazyData()
    })

    const v$ = useVuelidate(rules, form)

    return {
      dt, 
      loading,
      saving,
      recepcion,
      chofer,
      tipoMadera,
      fsc,
      fscs,
      recepciones,
      productos,
      lineas,
      columns,
      choferes,
      tipoMaderas,
      tipoDocReceps,
      proveedores,
      recepcionistas,
      transportistas,
      filters,
      selectedRecepciones,
      deleteRecepcionesDialog,
      deleteRecepcionDialog,
      recepcionDialog,
      submitted,
      filteredTipoDocReceps,
      filteredProveedores,
      filteredRecepcionistas,
      filteredFscs,
      filteredChoferes,
      filteredTipoMaderas,
      filteredDestinos,
      filteredOrigenes,
      filteredProductos,
      filteredTransportistas,
      selectedProductos,
      porciva,
      nextid,
      v$,
      form,
      messages,
      netoRecepcion,
      totalCantDespacho,
      totalM3Despacho,
      totalCantRecepcion,
      totalM3Recepcion,
      totalMRRecepcion,
      totalMRDespacho,
      ivaRecepcion,
      totalRecepcion, 
      upperCase,
      setProductos,     
      hideDialog,
      openNew,
      confirmDeleteRecepcion,
      deleteSelectedRecepciones,
      deleteRecepcion,
      addChofer,
      frmchofer,
      confirmDeleteSelected,
      searchTipoDocRecep,
      searchProducto,
      searchProveedor,
      searchRecepcionista,
      searchFsc,
      searchChofer,
      searchDestino,
      searchTransportista,
      searchOrigen,
      searchTipoMadera,
      editRecepcion,
      saveRecepcion,
      storeRecepcion,
      formatCurrency,
      onCellEditComplete,
      addLinea,
      toggleDelete,
      buscarCodFscProveedor,
      getCodFsc,
      setFscCodigo,
      csr1,
      csr2,
      configColumns,
      oldTMadera,
      recepcionPDF,
      informeData,
      totalRecords,
      first,
      lazyParams,
      loadLazyData,
      onPage,
      onSort,
      onFilter,
      duplicarGuia,
      getPDFSII,
      dummyClick,
      initFilters
    }
  },
}
</script>